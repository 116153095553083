import React from "react";
// import logo from "../public/img/Splash_acccenture_big_logo.svg";
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';


export function Footer(){
    return <>
    <Hidden only={['sm','md','lg','xl']}>
          <div id="footer-blocksm" >
        <Grid container>
       
    {/* <Grid item md={12} sm={12} xs={12} id="footerbelowline"></Grid>
    <Grid container xs={12} sm={12} >
    <Grid item xs={12} sm={2}> 
    <a   target='_blank' href='https://www.accenture.com/in-en'> <img src={logo} style={{ width: '2.875rem',paddingTop: '2.188rem'}} alt="no se"/></a>
</Grid>
<Grid item xs={4} sm={2}  className="footertext"><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/about/company-index'>ABOUT US </a>
</Grid>
<Grid item xs={4} sm={2}  className="footertext" style={{ textAlign: 'center'}}><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/about/contact-us'>CONTACT US </a>
</Grid>
<Grid item xs={4} sm={2}  className="footertext" style={{ textAlign: 'end'}}><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/careers'>CAREERS </a>
</Grid>
<Grid item xs={12} sm={2}  className="footertext" style={{paddingTop:'1rem'}}><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/about/location-index'>LOCATIONS </a>
</Grid>
</Grid>
<Grid item md={12} sm={12} xs={12} id="footerline"></Grid> */}
<Grid container xs={12} sm={12} >
<Grid item xs={4} sm={2}  className="footertextNew"><a style={{  fontSize: '0.6875rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/us-en/about/privacy-policy'>Privacy Statement </a>
</Grid>
<Grid item xs={4} sm={2}  className="footertextNew" style={{ textAlign: 'end'}}><a style={{  fontSize: '0.6875rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/us-en/support/terms-of-use'>Terms & Conditions </a>
</Grid>
<Grid item xs={4} sm={2}  className="footertextNew" style={{ textAlign: 'end'}}><a style={{  fontSize: '0.6875rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/us-en/support/company-cookies-similar-technology'>Cookie Policy </a>
</Grid>
<Grid item xs={5} sm={2}  className="footertextNew" style={{marginTop:'2rem'}}><a style={{  fontSize: '0.6875rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/us-en/support/accessibility-statement'>Accessibility Statement </a>
</Grid>
<Grid item xs={2} sm={1}  className="footertextNew" style={{marginTop:'2rem', textAlign: 'end'}}><a style={{  fontSize: '0.6875rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/us-en/about/site-map'>Sitemap </a>
</Grid>
<Grid item xs={12} sm={3}  className="footertextNew" style={{marginTop:'2rem'}}><a style={{  fontSize: '0.6875rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='Do Not Sell My Personal Information (for CA)'>Do Not Sell My Personal Information (for CA) </a>
</Grid>
<Grid  item xs={12} sm={3} md={3} className="footertextNew" style={{marginTop:'2rem',marginBottom:'1rem'}}>
@ 2021 Accenture. All Rights Reserved.
</Grid>
</Grid>
  
    </Grid>
    </div>
     </Hidden>
    <Hidden only="xs">
    <div id="footer-block" >
        <Grid container>
       
    {/* <Grid item md={12} sm={12} xs={12} id="footerbelowline"></Grid>
    <Grid container xs={12} sm={12} >
    <Grid item xs={2} sm={1}> 
    <a   target='_blank' href='https://www.accenture.com/in-en'> <img src={logo} style={{ width: '2.875rem',paddingTop: '2.188rem'}} alt="no se"/></a>
</Grid>
<Grid item xs={12} sm={2}  md={2} className="footertext"><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/about/company-index'>ABOUT US </a>
</Grid>
<Grid item xs={12} sm={2} md={2} className="footertext"><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/about/contact-us'>CONTACT US </a>
</Grid>
<Grid item xs={12} sm={2} md={2} className="footertext"><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/careers'>CAREERS </a>
</Grid>
<Grid item xs={12} sm={2} md={2} className="footertext"><a style={{  fontSize: '0.833rem', color:'#ffffff', textDecoration: 'underline' }}  target='_blank' href='https://www.accenture.com/in-en/about/location-index'>LOCATIONS </a>
</Grid>
</Grid>
<Grid item md={12} sm={12} xs={12} id="footerline"></Grid> */}
<Grid container xs={12} sm={12} >
<Grid container  sm={8} md={9}>
<Grid className="footertextNew"><a style={{  fontSize: '0.667rem', color:'#ffffff' ,textDecoration:'underline' }}  target='_blank' href='https://www.accenture.com/us-en/about/privacy-policy'>Privacy Statement </a>
</Grid>
<Grid className="footertextNew" style={{textAlign: 'center'}}><a style={{  fontSize: '0.667rem', color:'#ffffff' ,textDecoration:'underline' }}  target='_blank' href='https://www.accenture.com/us-en/support/terms-of-use'>Terms & Conditions </a>
</Grid>
<Grid className="footertextNew" style={{textAlign: 'center'}}><a style={{  fontSize: '0.667rem', color:'#ffffff' ,textDecoration:'underline' }}  target='_blank' href='https://www.accenture.com/us-en/support/company-cookies-similar-technology'>Cookie Policy </a>
</Grid>
<Grid className="footertextNew"><a style={{  fontSize: '0.667rem', color:'#ffffff' ,textDecoration:'underline' }}  target='_blank' href='https://www.accenture.com/us-en/support/accessibility-statement'>Accessibility Statement </a>
</Grid>
<Grid className="footertextNew"><a style={{  fontSize: '0.667rem', color:'#ffffff' ,textDecoration:'underline' }}  target='_blank' href='https://www.accenture.com/us-en/about/site-map'>Sitemap </a>
</Grid>
<Grid className="footertextNew"><a style={{  fontSize: '0.667rem', color:'#ffffff' ,textDecoration:'underline' }}  target='_blank' href='https://www.accenture.com/us-en/form-do-not-sell-my-personal-information'>Do Not Sell My Personal Information (for CA) </a>
</Grid>
</Grid>
<Grid container  sm={4} md={3} style={{float:'right'}}>
<Grid  item xs={12} sm={12} md={12} className="footertextNew"style={{textAlign:'right', marginTop: "2.5rem"}}>
@ 2021 Accenture. All Rights Reserved.
</Grid>
</Grid>
</Grid>
  
    </Grid>
    </div>
    </Hidden>
    </>
}