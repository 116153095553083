import { TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { withCommonScreen } from "../../Components/WithCommonScreen";
import { ArrayIndexType } from "../../Common/ArrayIndexType";
import ICommonStateProps from "../../Common/ICommonStateProps";
import { createInitialArrayIdxType } from "../../Common/Functions";
import SrvResponse from "../../Common/DTO/SrvResponse";
import IndustryTypeOptions, { GeographiesElement, IndustriesElement, SeniorityElement, SizesElement } from "../../Common/DTO/IndustryTypeOptions";
import { EndpointData } from "../../Common/EndpointData";
import ResultsContent from "./ResultsContent";
import ResultsFooter from "./ResultsFooter";
import Result from '../../Common/DTO/Results';
import BenchmarkScore from '../../Common/DTO/BenchmarkScore';
import QuartileScore from "../../Common/DTO/QuartileScore";
import * as _ from "lodash";
import { useModal } from './useModal';
import { saveAs } from 'file-saver';
import analyticsOfPage from "../../Components/AdobeAnalytics";

const Frame = withCommonScreen(ResultsContent, ResultsFooter)

 /* Amplify.configure({
  Auth: {
    mandatorySignId: false,
    region: 'us-east-1',
    userPoolId: "us-east-1_hArqYlLKb",
    userPoolWebClientId: "1ev47272hi1leq8nj2q287jc1q",
   identityPoolId: "us-east-1:d94b8d8c-8592-483d-9fb1-06077a80e857",
  },
  Storage: {
      bucket: "dev-rla-artifacts"
  }
})  */

export interface IResultPageState{
  errorMessage:string
  roleerrorMessage:string,
  organisationerrorMessage:string;
  usererrorMessage:string,
  lastNameerrorMessage:string,
    showContactPopup :boolean,
    onCancel :() => void;
    onClickContactInfo: (t: boolean) => void
    results: Result,
    benchmarkResults : BenchmarkScore,
    landingdetails: { industryValue : string,
      geographyValue:  string
      sizeValue: string ,
    seniorityValue:string,
    perspectiveValue: string,
  organisationName :string},
    selectOption:string,
    lroScoreValues: [],
    organisationScoreValues  : [],
    sizeScoreValues  : [],
  industryScoreValues  : [],
    geographyScoreValues  : [],
    organisationBenchMarkScore  : number
    sizeBenchMarkScore  : number,
  industryBenchMarkScore  : number,
    geographyBenchMarkScore  : number,
    onClickLandingInfo: (t: string) => void
    userNameChanged:(t: string,type:string) => void,
    textValidation: (t: string,type:string) => void;
    userName:string,
    lastName:string,
    country:string,
    roleName:string,
    emailAddress:string,
    organisationName:string,
    isAccepted:boolean;
    termsAccepted:boolean;
    onTermChanged: (t: boolean) => void;
    onClicked: (t: boolean) => void
    onStartSurveyClicked: ()=> void;
    isStartSurveyDisabled:boolean;
    emailValidation: (t: string) => void;
    showPopup:boolean,
    onSend :() => void;
    showConfirmPopup:boolean;
    loadingIcon:boolean,
    confirmMessage: string;
    visible:boolean;


}

function environmentCheck(){
  if (process.env.REACT_APP_BASE_URL === 'production') {
      return (`https://kwnruy0k79.execute-api.us-east-1.amazonaws.com/prod-sus/rla`)
  }
  if (process.env.REACT_APP_BASE_URL === 'staging') {
      return (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/uat/rla`)
  }
  if (process.env.REACT_APP_BASE_URL === 'development') {
      return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev/rla`)
  }
  else{
      return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev/rla`)
  }
}

function pdfCheck(){
  if (process.env.REACT_APP_BASE_URL === 'production') {
      return (`https://kwnruy0k79.execute-api.us-east-1.amazonaws.com/prod-sus`)
  }
  if (process.env.REACT_APP_BASE_URL === 'staging') {
      return (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/uat`)
  }
  if (process.env.REACT_APP_BASE_URL === 'development') {
      return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev`)
  }
  else{
      return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev`)
  }
}

function mailCheck(){
  if (process.env.REACT_APP_BASE_URL === 'production') {
      return (`https://oppjgp4lg4.execute-api.us-east-1.amazonaws.com/prod-ack-email/ack_email`)
  }
  if (process.env.REACT_APP_BASE_URL === 'staging') {
      return (`https://ertwncpb78.execute-api.us-east-1.amazonaws.com/uat_ack_email/uat_ack_email`)
  }
  if (process.env.REACT_APP_BASE_URL === 'development') {
      return  (`https://3gsylexxk6.execute-api.us-east-1.amazonaws.com/dev_ack_email/ack_email`)
  }
  else{
      return  (`https://3gsylexxk6.execute-api.us-east-1.amazonaws.com/dev_ack_email/ack_email`)
  }
}

function EmailCheck(){
  if (process.env.REACT_APP_BASE_URL === 'production') {
      return (`https://3g8vly53i9.execute-api.us-east-1.amazonaws.com/uat_email/sendmail`)
  }
  if (process.env.REACT_APP_BASE_URL === 'staging') {
      return (`https://3g8vly53i9.execute-api.us-east-1.amazonaws.com/uat_email/sendmail`)
  }
  if (process.env.REACT_APP_BASE_URL === 'development') {
      return  (`https://3g8vly53i9.execute-api.us-east-1.amazonaws.com/dev_email/sendmail`)
  }
  else{
      return  (`https://3g8vly53i9.execute-api.us-east-1.amazonaws.com/dev_email/sendmail`)
  }
}

function EmailCheckStatus(){
  if (process.env.REACT_APP_BASE_URL === 'production') {
      return (`https://gyfm82okk9.execute-api.us-east-1.amazonaws.com/uat_emailstatus/emailstatus`)
  }
  if (process.env.REACT_APP_BASE_URL === 'staging') {
      return (`https://gyfm82okk9.execute-api.us-east-1.amazonaws.com/uat_emailstatus/emailstatus`)
  }
  if (process.env.REACT_APP_BASE_URL === 'development') {
      return  (`https://gyfm82okk9.execute-api.us-east-1.amazonaws.com/dev_emailstatus/emailstatus`)
  }
  else{
      return  (`https://gyfm82okk9.execute-api.us-east-1.amazonaws.com/dev_emailstatus/emailstatus`)
  }
}

export  function Results(props: ICommonStateProps<IResultPageState>){
  const { state, setState } = props
  const { isShown, toggle } = useModal();
  const [update, setUpdate] = useState(false);
    const history = useHistory()
    
    const showContactPopup = props.state.showContactPopup ?? false
    const selectOption = props.state.selectOption ?? "";
    const results = props.state.results ??  {} as Result;
    const lroScoreValues = props.state.lroScoreValues ??  [];
   const termsAccepted = props.state.termsAccepted ??  false;
   const isAccepted = props.state.isAccepted ??  false;
   const userName = props.state.userName ??  "";
   const lastName = props.state.lastName ??  "";
   const country = props.state.country ??  "-1";
      const roleName = props.state.roleName ??  "";
      const emailAddress = props.state.emailAddress ??  "";
      const organisationName = props.state.organisationName?? "";
      const visible = props.state.visible ??  false;
    const benchmarkResults = props.state.benchmarkResults ??  {} as BenchmarkScore;
  //  if( props.state.benchmarkResults)
  //   localStorage.setItem('benchMarkscore' ,JSON.stringify(props.state.benchmarkResults) );
    if( props.state?.results?.preferenceId)
    localStorage.setItem('preferenceId' ,JSON.stringify(props.state.results.preferenceId) );
           
    const errorMessage = props.state.errorMessage ?? ""
    const usererrorMessage = props.state.usererrorMessage ?? ""
    const lastNameerrorMessage = props.state.lastNameerrorMessage ?? ""
    const roleerrorMessage = props.state.roleerrorMessage ?? ""   
    const organisationerrorMessage = props.state.organisationerrorMessage ?? "" 
    const showPopup = props.state.showPopup ?? false;
    const showConfirmPopup = props.state.showConfirmPopup ?? false ;
    const loadingIcon = props.state.loadingIcon ?? false ;
    const confirmMessage = props.state.confirmMessage ?? "" ;
    const organisationScoreValues = props.state.organisationScoreValues ??  [];
    const sizeScoreValues = props.state.sizeScoreValues ??  [];
    const industryScoreValues = props.state.industryScoreValues ??  [];
    const geographyScoreValues = props.state.geographyScoreValues ??  [];
    const organisationBenchMarkScore = props.state.organisationBenchMarkScore ??  0;
    const sizeBenchMarkScore = props.state.sizeBenchMarkScore ??  0;
    const industryBenchMarkScore = props.state.industryBenchMarkScore ??  0;
    const geographyBenchMarkScore = props.state.geographyBenchMarkScore ?? 0;
    const landingdetails = props.state.landingdetails ??   { industryValue : "",
      geographyValue:  "",
      sizeValue: "" ,
      seniorityValue:"",
      organisationName :"",
      perspectiveValue: ""
    }
    
   /*  var data = {
      "preferenceId": 161,
      "elementScores": [
        {
          "elementId": 11,
          "elementName": "Stakeholder Inclusion",
          "elementScore": 14
        },
        {
          "elementId": 12,
          "elementName": "Emotion & Intuition",
          "elementScore": 14
        },
        {
          "elementId": 13,
          "elementName": "Mission & Purpose",
          "elementScore": 14
        },
        {
          "elementId": 14,
          "elementName": "Technology & Innovation",
          "elementScore": 14
        },
        {
          "elementId": 15,
          "elementName": "Intellect & Insight",
          "elementScore": 14
        }
      ],
      "userERLIScore": 14,
      "archetypeName": "Archetype 3",
      "summaryText": "There seems to be an appetite for continuous development at [Org name]. From what you told us, we think learning processes and practices are strong and embedded in the habits of [Org Name's] people. As a result orthodoxy is unlikely to be unchallenged, innovative ideas are common place and critical thinking is the norm, not the exception.",
      //"strengthsText": "Arch3 strengths",
      "strengthsText": "Against all the 5 elements, your organisation is performing most strongly in its Intellect and Insight. Your organisation is consistently seeking ever-improving paths to success by Embracing continuous learning and knowledge exchange.",
      "areasToDevelopText": "Nos is the time to work on the organization's ability to ensure all teams and all decisions are enriched by diverse participation and perspectives. This is what we call stakeholder inclusion. Organizations with strong Stakeholder Inclusion make their people feel like they belong and are valued which enhances individual and group performance"
  
    }
    */
  
 
   let companyArray = {};
   
   
    const companyDetails = history? history.location.state?.data : null;
    const questionData = history ? history.location.state?.questionData : null;
   
    
  let geographyDetails =  companyDetails?.geography.array[companyDetails.geography.idx]
  let industryDetails =  companyDetails?.industries.array[companyDetails.industries.idx]
  let revenueDetails =  companyDetails?.revenue.array[companyDetails.revenue.idx]
  let senorityDetails =  companyDetails?.seniority.array[companyDetails.seniority.idx]
  let perspectiveDetails = companyDetails?.perspective.array[companyDetails.perspective.idx]
     
  let  responseQuestions :any =[];
  questionData?.forEach(function(item,index){
      let objArray = 
   { "questionId" : item.id,
        "response" : item.scale,
        "comment" : item.comments 
    };
    responseQuestions[index] = objArray;
  });


 

  const requestdata = {"industryId" : industryDetails?.industryId ,
  "geographyId":  geographyDetails?.geographyId,
  "sizeId" : revenueDetails?.sizeId,
  "seniorityId" : senorityDetails?.seniorityId,
  "organisationName" : companyDetails?.companyName ? companyDetails.companyName : "",
  "diagnosticPerspective": perspectiveDetails?.perspectiveValue,
  "responses"  : responseQuestions
};

     useEffect(() => {  
      //  analyticsOfPage("results");
      window['getDataModel']('results')
      window['getEvent']("results")

      if( localStorage.getItem('splashcheck') != 'true') 
      {
          history.push('/')
      }
      else if(localStorage.getItem('surveycheck') != 'true'){
        history.push('/landing')
      }
      else{
      var environmentUrl = environmentCheck();
      let headerValue = new Headers();
      headerValue.append('Authorization', sessionStorage.getItem('accessToken'));
      headerValue.append('txnId', sessionStorage.getItem('transId'));
      headerValue.append('applName', 'survey-ms');
      headerValue.append('Content-Security-Policy', `default-src 'self'`);
      headerValue.append('X-Frame-Options', 'DENY');
      headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
      headerValue.append('X-XSS-Protection', '1');
      headerValue.append('X-Content-Type-Options','nosniff');
      fetch(`${environmentUrl}/v1.0/generate-custom-token`,{headers: headerValue}
      )
      .then(t => {
       if(t.ok === true){
           return t.json()
       }
       else{
           throw "Error fetching data"
       }
   })
            .then(t => {
               
                if(t.resultCode === "0"){
                  sessionStorage.setItem('accessToken', t.resultObject.customToken);
                  sessionStorage.setItem('transId', t.resultObject.transactionId);
                    resultsApi();
                }
                else
                    throw "Error on data"
            })
           
            .catch(r => {
                // alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue."); 
                console.log(r)
            })

          
  
 } }, [])

          function resultsApi(){
           
              let headerValue = new Headers();
              headerValue.append('Content-Type' , 'application/json');
             
         
              headerValue.append('Authorization', sessionStorage.getItem('accessToken'));
              headerValue.append('txnId', sessionStorage.getItem('transId'));
              headerValue.append('applName', 'survey-ms');
              headerValue.append('Content-Security-Policy', `default-src 'self'`);
              headerValue.append('X-Frame-Options', 'DENY');
              headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
              headerValue.append('X-XSS-Protection', '1');
              headerValue.append('X-Content-Type-Options','nosniff');
             var environmentUrl = environmentCheck();

             fetch(`${environmentUrl}/v1.0/results`,
            {method: "POST",headers: headerValue, body :JSON.stringify( requestdata),
         
           })
                 .then(item=> {
                     if(item.ok === true){
                         return item.json()
                     }
                     else{
                         throw "Error fetching data"
                     }
                 })
                 
                 .then(item => {
                     const data = item as SrvResponse<Result>
                     if(data.resultCode === "0"){
                         return data.resultObject!
                     }
                         else if(data.resultCode === "1"){
                           alert("You are not authorized to perform this action");
                           } 
                        
                     else{
                         throw "Error on data"
                     }
                 })
                 .then(v => {
                     const data = v;
                 localStorage.setItem('resultspagecheck' ,'false')
                    
                       setState({
                         selectOption:'',
                           results: {
                             preferenceId: data.preferenceId,
                             elementScores: data.elementScores, 
                       userERLIScore: data.userERLIScore,
                       
                           archetypeName : data.archetypeName,
                           summaryText: data.summaryText,
                           
                       strengthsText: data.strengthsText,
                       areasToDevelopText: data.areasToDevelopText,
                       scoreTagDTO: data.scoreTagDTO
                       
                           },
                       lroScoreValues  : _.map(data.elementScores, 'elementScore'),
                       
                       landingdetails :{  industryValue : industryDetails.industryValue,
                         geographyValue:  geographyDetails.geographyValue,
                         sizeValue: revenueDetails.sizeValue,
                         seniorityValue: senorityDetails.seniorityValue,
                         perspectiveValue: perspectiveDetails.perspectiveValue,
                         organisationName: companyDetails.companyName
                      
                             }
         
                        }) ;
                       
                       
                       
                   
               })
                .catch(r => {
                //  alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue.");    
                 console.log(r)
                })
         
                invokeBenchMarkScore();
                getQuartileScores();
               
          }
          
      

      function  invokeBenchMarkScore(){
        var environmentUrl = environmentCheck();
        let headerValue = new Headers();
        headerValue.append('Content-Type' , 'application/json');
        headerValue.append('Authorization', sessionStorage.getItem('accessToken'));
        headerValue.append('txnId', sessionStorage.getItem('transId'));
        headerValue.append('applName', 'survey-ms');
        headerValue.append('Content-Security-Policy', `default-src 'self'`);
        headerValue.append('X-Frame-Options', 'DENY');
        headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
        headerValue.append('X-XSS-Protection', '1');
        headerValue.append('X-Content-Type-Options','nosniff');
               fetch(`${environmentUrl}/v1.0/benchmark-results?sizeId=`+revenueDetails?.sizeId +`&industryId=` + industryDetails?.industryId +'&geographyId='+geographyDetails?.geographyId , {headers: headerValue})
          .then(v => {
              if(v.ok === true){
                  return v.json()
              }
              else{
                  throw "Error fetching data"
              }
          })
          .then(v => {
              const data = v as SrvResponse<BenchmarkScore>
              if(data.resultCode === "0"){
                  return data.resultObject!
              }
                  else if(data.resultCode === "1"){
                    alert("You are not authorized to perform this action");
                    }
              else
                  throw "Error on data"
          })
          .then(v => {
              const data = v
             let  benchmarkResultsStore = {
              allOrganizations:v.results[0].allOrganizations,
              size: v.results[0].size,
              industry: v.results[0].industry,
              geography: v.results[0].geography
             }
              if(benchmarkResultsStore){
              localStorage.setItem('benchMarkscore' ,JSON.stringify(benchmarkResultsStore) );
              }
          /*     setState({
                benchmarkResults: {
                  results:v.results,                 
                  allOrganizations:v.results[0].allOrganizations,
                  geography: v.results[0].geography,
                  industry: v.results[0].industry,
                  size: v.results[0].size
              },
              
              
              organisationBenchMarkScore  : v.results[0].allOrganizations[0]?.benchmarkScore,
              sizeBenchMarkScore  : v.results[0].size[0]?.benchmarkScore,
            industryBenchMarkScore  : v.results[0].industry[0]?.benchmarkScore,
              geographyBenchMarkScore  : v.results[0].geography[0]?.benchmarkScore,
              organisationScoreValues  : _.map(v.results[0].allOrganizations, 'elementScore'),
              sizeScoreValues  : _.map(v.results[0].size, 'elementScore'),
            industryScoreValues  : _.map(v.results[0].industry, 'elementScore'),
              geographyScoreValues  : _.map(v.results[0].geography, 'elementScore'),
            }) */
         
             
          })
          .catch(r => {
        // alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
        alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue.");   
            console.log(r)
          })
        }

  function getQuartileScores() {
    const environmentUrl = environmentCheck();

    const headerValue = new Headers();
    headerValue.append('Content-Type', 'application/json');
    headerValue.append('Authorization', sessionStorage.getItem('accessToken'));
    headerValue.append('txnId', sessionStorage.getItem('transId'));
    headerValue.append('applName', 'survey-ms');
    headerValue.append('Content-Security-Policy', `default-src 'self'`);
    headerValue.append('X-Frame-Options', 'DENY');
    headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
    headerValue.append('X-XSS-Protection', '1');
    headerValue.append('X-Content-Type-Options', 'nosniff');

    fetch(`${environmentUrl}/v1.0/top-quartile-data?sizeId=${revenueDetails?.sizeId}&industryId=${industryDetails?.industryId}&geographyId=${geographyDetails?.geographyId}`, { headers: headerValue })
      .then(response => {
        if (response.ok === true) {
          return response.json()
        }
        else {
          throw "Error fetching data"
        }
      })
      .then(response => {
        const data = response as SrvResponse<QuartileScore>;
        if (data.resultCode === "0") {
          return data.resultObject!
        }
        else if (data.resultCode === "1") {
          alert("You are not authorized to perform this action");
        }
        else {
          throw "Error on data"
        }
      })
      .then(resultObject => {
        const { allOrganizations, size, industry, geography } = resultObject.results[0];

        const topQuartileScore = { allOrganizations, size, industry, geography };

        localStorage.setItem('topQuartileScore', JSON.stringify(topQuartileScore));
        setUpdate(true);
      })
      .catch(error => {
        // alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
        alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue.");   
        console.log(error);
      });
  }

function onClickContactInfo(t :boolean){
  setState({showContactPopup : t})

}
function onClickLandingInfo(t:string){
 
 
  setState({selectOption : t  })
 

}

/* function onStartSurveyClicked(){
  SetS3Config(uploadUrl);
             const result =   Storage.put('LROReport'+localStorage.getItem("preferenceId") +'.pdf', file , {
                  contentType: "application/pdf",
                  //level: 'public'
                }).then((result :any) =>{
                   console.log(result)
                   let requestData = {email :emailAddress,fileName :result?.key,preferenceId:localStorage.getItem("preferenceId")}
                   let headerValue = new Headers();
                   var emailUrl = EmailCheck();
                });
              });
            },1000);
            setTimeout(() => { history.push('/endPage')},1000);
    } */
  
    
    function textChange(text,page)
    {
      if(text){
       // var replaceText =text.replaceAll('\u2022','');
       // var s =replaceText.replaceAll('\t','');
      let customItems = text;
    
    customItems = customItems.split("\n");
    if(customItems.length > 0){
      customItems.forEach((data ,i)=> {
    var  trimText= data.trim();
     var  bulleticon ='\u2022';
    var  appendedtext = `${bulleticon} ${trimText}`
  //  var appendedtext = bulleticon.concat(trimText);
   customItems[i] = appendedtext;
   

      });
    customItems = customItems.join("\n");
    if(page === 'strength'){
     return  customItems;
    
    }
    else if(page==='areas'){
    return customItems;
    }
      }
    }
    }

   
  function onStartSurveyClicked(){
  //  analyticsOfPage("download");
  window['getDataModel']('results')
  window['getEvent']("download")
  //window.scrollTo(0,0);  
  let headerValue = new Headers();
  let requestUser = {
    "preferenceId":  localStorage.getItem("preferenceId"),
    "userFirstName": userName,
    "userLastName": lastName,
    "jobTitle": roleName,
    "countryOrLocation": country,
    "userEmail": emailAddress,
    "organizationName": organisationName? organisationName : landingdetails?.organisationName,
    "optInResponse" : isAccepted ?'Y' :'N'
  }
  
  
  headerValue.append('Content-Type' , 'application/json');
  headerValue.append('Authorization', sessionStorage.getItem('accessToken'));
  headerValue.append('txnId', sessionStorage.getItem('transId'));
  headerValue.append('applName', 'survey-ms');
  headerValue.append('Content-Security-Policy', `default-src 'self'`);
  headerValue.append('X-Frame-Options', 'DENY');
  headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
  headerValue.append('X-XSS-Protection', '1');
  headerValue.append('X-Content-Type-Options','nosniff');

  var environmentUrl = environmentCheck();
  props.setState({
   loadingIcon:true
    
  });
  
   fetch(`${environmentUrl}/v1.0/user-details`,
  {method: "POST",headers: headerValue, body :JSON.stringify( requestUser),

 })
        .then(t => {
           if(t.ok === true){
               return t.json()
           }
           else{
               throw "Error fetching data"
           }
       })  
       .then(t => {
        if(t.resultCode === "0"){
          return t.resultMessage!
      }
       
            if(t.resultCode === "1"){
            alert("You are not authorized to perform this action")
            }
       
    })

       if (localStorage.getItem("benchMarkscore") && localStorage.getItem("benchMarkscore") != "{}") {
        let benchMarkResults = JSON.parse(localStorage.getItem('benchMarkscore')!);
  
  
        var organisationBenchMarkScore = benchMarkResults.allOrganizations[0].benchmarkScore
        var sizeBenchMarkScore = benchMarkResults.size.length > 0 ? benchMarkResults.size[0].benchmarkScore : 0;
        var industryBenchMarkScore = benchMarkResults.industry.length > 0 ?  benchMarkResults.industry[0].benchmarkScore : 0;
        var geographyBenchMarkScore = benchMarkResults.geography[0].benchmarkScore
        var organisationScoreValues = _.map(benchMarkResults.allOrganizations, 'elementScore')
        var sizeScoreValues = _.map(benchMarkResults.size, 'elementScore')
        var industryScoreValues = _.map(benchMarkResults.industry, 'elementScore')
        var geographyScoreValues = _.map(benchMarkResults.geography, 'elementScore')
      }
let requestPayload =
       {
        "user":{
          "name":userName,"role":roleName,"careerLevel":landingdetails?.seniorityValue ,"organizationName":organisationName? organisationName : landingdetails?.organisationName,
          "industry":'Industry Benchmark - ' + landingdetails?.industryValue,
          "geography":'Geography Benchmark - ' + landingdetails?.geographyValue,
          "annualRevenue":'Annual Revenue Benchmark - ' +​ landingdetails?.sizeValue,
          "industryName" :landingdetails?.industryValue,
          "geographyName":landingdetails?.geographyValue,
          "annualRevenueNmae" :landingdetails?.sizeValue,
          "diagnosticPerspective": landingdetails?.perspectiveValue.toLowerCase().includes("individual") ? "Individual" : "Organizational",
          "surname":lastName
        },
        "all": {
          "benchmark":{
            "total":organisationBenchMarkScore,
            "values":organisationScoreValues
          },
          "score":{
            "total":results.userERLIScore,
            "values":lroScoreValues
          }
        },
        "industry": {
          "benchmark":{
            "total":landingdetails?.industryValue !== 'Other' ? industryBenchMarkScore : 'N/A',
            "values":industryScoreValues
          },
          "score":{
            "total":results.userERLIScore,
            "values":lroScoreValues
          }
        },
        "geography": {
          "benchmark":{
            "total":geographyBenchMarkScore,
            "values":geographyScoreValues
          },
          "score":{
            "total":results.userERLIScore,
            "values":lroScoreValues
          }
        },
        "annual": {
          "benchmark":{
            "total":landingdetails?.sizeValue !== 'N/A' ? sizeBenchMarkScore :'N/A',
            "values":sizeScoreValues
          },
          "score":{
            "total":results.userERLIScore,
            "values":lroScoreValues
          }
        },
        "archetypeName": results.archetypeName,
        "summaryText": results.summaryText,
        "strengthsText":textChange( results.strengthsText ,'strength'),
        "areasToDevelopText":textChange(results.areasToDevelopText,'areas'),
        "scoreTagDTO": {
          "ciScore": results.scoreTagDTO.ciScore,
          "hcScore": results.scoreTagDTO.hcScore,
          "tagMessage": results.scoreTagDTO.tagMessage
        },
    }
    let headerValueNew =  new Headers();
    headerValueNew.append('Content-Type' , 'application/json');
    headerValueNew.append('Authorization', sessionStorage.getItem('accessToken'));
    headerValueNew.append('txnId', sessionStorage.getItem('transId'));
    headerValueNew.append('applName', 'survey-ms');
    headerValueNew.append('Content-Security-Policy', `default-src 'self'`);
    headerValueNew.append('X-Frame-Options', 'DENY');
    headerValueNew.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
    headerValueNew.append('X-XSS-Protection', '1');
    headerValueNew.append('X-Content-Type-Options','nosniff');

    var pdfUrl = pdfCheck();
    fetch(`${pdfUrl}/generator-pdf/v1.0/pdf`,
                   {method: "POST", headers:headerValueNew, body :JSON.stringify( requestPayload)
               
                 }   )
                //  .then(function (response) {
                //    const temp = response.blob();
                //    return temp;
                //  }
                //  )
                //  .then(function(blob) {
                //   saveAs(blob, "AccentureSustainableOrgReport.pdf")
                 
                // })

                  .then((response) => {
                    if(response.ok === true){
                      return response.blob();
                    }
                    else if (response.status === 403) {
                      return response.text()
                    }
                    else{
                        throw "Error fetching data"
                    }
                }
            )
            .then(response => {
              if (response?.size && typeof response?.size === "number") {
                saveAs(response, "AccentureSustainableOrgReport.pdf")                
              }
              else {
                throw response;
              }
            })

  //------------------------------------Start - Email Functionality commented as per PO requirement------------------------------
  //
  //           .then(function(response){
  //                 const mailUrl = mailCheck();
  //                 let headerValue1 =  new Headers();
  //               //  headerValue1.append('Authorization', sessionStorage.getItem('accessToken'));
  // /*                 headerValue1.append('Content-Type' , 'application/json');
  //                 headerValue1.append('Access-Control-Allow-Headers' , 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,Content-Security-Policy,Set-Cookie,X-Content-Type-Options,X-Frame-Options,Strict-Transport-Security,X-XSS-Protection');
  //                 headerValue1.append('Access-Control-Allow-Methods' , '*'); */
  //              //   headerValue1.append('Access-Control-Allow-Origin' , '*');
  //                 headerValue1.append('Content-Type' , 'application/json');
              
  //                /*  headerValue1.append('Content-Security-Policy', `default-src 'self'`);
  //                 headerValue1.append('X-Frame-Options', 'SAMEORIGIN');
  //                 headerValue1.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
  //                 headerValue1.append('X-XSS-Protection', '1');
  //                 headerValue1.append('X-Content-Type-Options','nosniff');  */

  //                 let mailPayload = {
  //                   "user": {
  //                     "erliScore": results.userERLIScore,
  //                     "preferenceId": localStorage.getItem("preferenceId"),
  //                     "firstName": userName,
  //                     "lastName": lastName,
  //                     "jobTitle": roleName,
  //                     "countryOrLocation": country,
  //                     "email": emailAddress,
  //                     "oraganizationName": organisationName? organisationName : landingdetails?.organisationName,
  //                     "industryName" :landingdetails?.industryValue,
  //                     "geographyName":landingdetails?.geographyValue,
  //                     "sizeName" :landingdetails?.sizeValue
  //                   },
  //                   "benchmarkScores": {
  //                     "allOrganizations": organisationBenchMarkScore,
  //                     "industry": landingdetails?.industryValue !== 'Other' ? industryBenchMarkScore : 'N/A',
  //                     "grography": geographyBenchMarkScore,
  //                     "size": landingdetails?.sizeValue !== 'N/A' ? sizeBenchMarkScore :'N/A'
  //                   }
  //                 }
                  
  //                 fetch(`${mailUrl}`, {
  //                   method: "POST",
  //                  headers: headerValue1,
  //                   body: JSON.stringify(mailPayload)
  //                 })
  //                 .then(res => {
  //                   return res;
  //                 })
  //                 .catch(r => {
  //                   alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                          
  //                   console.log(r)
  //                 })
  //           })
  //
  //---------------------------------------------End----------------------------------------------------------------            
                       .catch(r => {
                        // alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                        alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue.");   
                        console.log(r)
                       })
                       props.setState({showPopup :false,loadingIcon:false });
                       localStorage.setItem('resultspagecheck', 'true') 
                       history.push('/endPage')
            

              /*     setTimeout(() => {
       
                    let div = document.createElement("div");
                    
                    div.style.width = "100%";
                    div.id = "pdfFooter"
                    div.style.background = "rgba(226, 225, 225, 0.99)";
                    
                    html2canvas( document.querySelector("#pdf")).then(canvas => {
                     if (/ipad|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) { */
                    
                 // const imgData =   (canvas.toDataURL('image/png'));

                // const pdf = new jsPDF();
                 // const pdf = new jsPDF('p', 'mm',[180,800],true);
                // pdf.addImage(imgData, 'JPEG', 15, 40, 0,0)
               /*   var imgWidth = 210; 
                 var pageHeight = 295;  
                 var imgHeight = canvas.height * imgWidth / canvas.width;
                 var heightLeft = imgHeight;
       
                 //var doc = new jsPDF();
                 var doc =new jsPDF('p', 'mm','a4',true);
                 
       
                 doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'MEDIUM');
                 heightLeft -= pageHeight;
       
                 while (heightLeft >= 0) {
                   position = heightLeft - imgHeight;
                   doc.addPage();
                   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'MEDIUM');
                   heightLeft -= pageHeight;
                 } */
                 //  pdf.addImage(imgData,'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
                  // file =  doc.output('blob');  
//                   var doc = new jsPDF('p', 'mm');
//                   setTimeout(() =>{
                    
//                 if(  imgData1 && imgData2 && imgData3){
// doc.addImage( imgData1, 'JPEG', 0, 0,210,297); 
// doc.addPage();// A4 sizes
// doc.addImage( imgData2, 'JPEG', 0, 0, 210,297); // img1 and img2 on first page

// do
    
 /*       
    }
    else{
      let position = 0;
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('p', 'mm',[180,800],true);
       pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height,'','FAST');
       props.setState({visible :false,showPopup :false,loadingIcon:false });
      pdf.save('SustainableOrgReport.pdf');
   setTimeout(() => { history.push('/endPage')},1000);

    } */
    //  setTimeout(() => { history.push('/endPage')},1000);
      
   



 
}


function printDiv() {
  var printContents = document.getElementById("pdf").innerHTML;
  var originalContents = document.getElementById("resultspage").innerHTML;
  document.getElementById("resultspage").innerHTML = printContents;
  window.print();
  document.getElementById("resultspage").innerHTML = originalContents;
}
 

 /* function SetS3Config(bucket:any) {
  Storage.configure({
      //bucket:   "dev-rla-artifacts",
      bucket:   "rldiagnosticspdf",
     //level: "public",
      region: "us-east-1",
      identityPoolId: "us-east-1:d94b8d8c-8592-483d-9fb1-06077a80e857"

     
  });
} */

function emailValidation(t:string){
  const validationRule = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  let emailValid = validationRule.test(t) ? true : false;
  if(emailValid == false){
    props.setState({
      errorMessage : "Please enter valid email address",
      emailAddress:""
  })
}
else{
  props.setState({errorMessage :""})
}
}

function textValidation(t:string,type:string){
  
  const validationRule = /^[a-zA-Z -]*$/
  const orgNameRule = /^[A-Za-z0-9 ]+$/  
  let textValid = validationRule.test(t) ? true : false;
  let orgNameValid = orgNameRule.test(t) ? true : false;
  if(orgNameValid == false && type== 'organisation'){
    props.setState({
      organisationerrorMessage :  "Organization name should contain letters or numbers only",
      organisationName : ''
  })
  }

  if(textValid == false){
    
    if(type== 'username'){
    props.setState({
      usererrorMessage :   "First Name should contain letters only" ,
      userName : ''
  })
}
else if(type== 'lastname'){
  props.setState({
    lastNameerrorMessage :   "Last Name should contain letters only" ,
    lastName : ''
})
}
else if(type== 'rolename'){
  props.setState({
    roleerrorMessage :  "Job Title should contain letters only",
    roleName : ''
})
}

}
else{
  props.setState({roleerrorMessage :"",usererrorMessage :'',lastNameerrorMessage:'',  
  organisationerrorMessage:""})
}
}



const onCancel =() =>{
  setState({showPopup :false,showConfirmPopup : false, organisationName:"",visible:false,
  userName:'',roleName :'',emailAddress:'',termsAccepted:false,isAccepted:false,lastName:'',country:'-1'})

}

function onSend(){
  props.setState({showPopup :true})
}
function userNameChanged(t :string,type:string){
  if (type=== 'username')
  setState({userName : t})
  if (type=== 'rolename')
  setState({roleName : t})
  if (type=== 'lastname'){
  setState({lastName : t})}
  if (type=== 'email'){
  setState({emailAddress : t})
 
  }
  if(type=="organisation"){
    setState({organisationName : t})
  }
  if(type=="country"){
    setState({country : t})
  }
  


}
    return <Frame 
        screenProps={{
            
          onClickContactInfo: onClickContactInfo,
          showContactPopup :showContactPopup,
          onClickLandingInfo :onClickLandingInfo,
          selectOption :selectOption,
          results :  results,
          landingdetails :landingdetails,
          lroScoreValues: lroScoreValues,
          organisationScoreValues  : organisationScoreValues,
          sizeScoreValues  : sizeScoreValues,
        industryScoreValues  : industryScoreValues,
          geographyScoreValues  : geographyScoreValues,
          benchmarkResults: benchmarkResults,
          organisationBenchMarkScore  : organisationBenchMarkScore,
          sizeBenchMarkScore  : sizeBenchMarkScore,
        industryBenchMarkScore  : industryBenchMarkScore,
          geographyBenchMarkScore  : geographyBenchMarkScore,
          userNameChanged:userNameChanged,
          termsAccepted: termsAccepted,
          isAccepted: isAccepted,
          onTermChanged: termsAccepted => props.setState({termsAccepted}),
          onClicked: isAccepted => props.setState({isAccepted}),
          userName:userName,
          lastName:lastName,
          country:country,
          roleName:roleName,
          emailAddress:emailAddress,
          organisationName:organisationName,
          isStartSurveyDisabled: (
            !( userName.trim()!==""
             &&lastName.trim()!=="" 
             && roleName.trim() !=="" 
             && emailAddress.trim()!==""  && country.trim() !=="-1" &&
             ((organisationName && organisationName.trim()!=="") || (landingdetails?.organisationName && landingdetails?.organisationName !==""))
            
            && termsAccepted === true)
        ),
        onStartSurveyClicked:onStartSurveyClicked,
        emailValidation:emailValidation,
        textValidation:textValidation,
        errorMessage:errorMessage,
        roleerrorMessage:roleerrorMessage,
        usererrorMessage:usererrorMessage,
        lastNameerrorMessage:lastNameerrorMessage,
        organisationerrorMessage:organisationerrorMessage,
        onCancel :onCancel,
        showPopup:showPopup,
        onSend:onSend,
        showConfirmPopup:showConfirmPopup,
        loadingIcon:loadingIcon,
        confirmMessage:confirmMessage,
        visible:visible

        

        }}
        footerProps={{
           
           
        }} 
        />
}


