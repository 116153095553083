import { TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";
import { withCommonScreen } from "../../Components/WithCommonScreen";
import { ArrayIndexType } from "../../Common/ArrayIndexType";
import ICommonStateProps from "../../Common/ICommonStateProps";
import { createInitialArrayIdxType } from "../../Common/Functions";
import SrvResponse from "../../Common/DTO/SrvResponse";
import IndustryTypeOptions, { GeographiesElement, IndustriesElement, SeniorityElement, SizesElement, PerspectiveElement } from "../../Common/DTO/IndustryTypeOptions";
import { EndpointData } from "../../Common/EndpointData";
import LandingPageContent from "./LandingPageContent";
import LandingPageFooter from "./LandingPageFooter";
const Frame = withCommonScreen(LandingPageContent, LandingPageFooter)
import * as _ from "lodash";
import analyticsOfPage from "../../Components/AdobeAnalytics";

export interface ILandingPageState{
    industries: ArrayIndexType<IndustriesElement>
    geography: ArrayIndexType<GeographiesElement>
    seniority: ArrayIndexType<SeniorityElement>
    perspective: ArrayIndexType<PerspectiveElement>
    revenue: ArrayIndexType<SizesElement>
    companyName: string
    termsAccepted: boolean
    hasGathered: boolean
    error: boolean
    errorMessage :string
    isTermAccepted:boolean
  isAccepted: boolean;
  onClicked: (t: boolean) => void;
}

function environmentCheck(){
    if (process.env.REACT_APP_BASE_URL === 'production') {
        return (`https://kwnruy0k79.execute-api.us-east-1.amazonaws.com/prod-sus/rla`)
    }
    if (process.env.REACT_APP_BASE_URL === 'staging') {
        return (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/uat/rla`)
    }
    if (process.env.REACT_APP_BASE_URL === 'development') {
        return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev/rla`)
    }
    else{
        return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev/rla`)
    }
}

export  function LandingPage(props: ICommonStateProps<ILandingPageState>){

    const history = useHistory()
   

    const industries = props.state.industries ?? createInitialArrayIdxType<IndustriesElement>()
    const geography = props.state.geography ?? createInitialArrayIdxType<GeographiesElement>()
    const seniority = props.state.seniority ?? createInitialArrayIdxType<SeniorityElement>()
    const perspective = props.state.perspective ?? createInitialArrayIdxType<PerspectiveElement>()
    const revenue = props.state.revenue ?? createInitialArrayIdxType<SizesElement>()
    const companyName = props.state.companyName ?? ""
    const termAccepted = props.state.termsAccepted ?? false;
    const isAccepted = props.state.isAccepted ??  false;
    const hasGathered = props.state.hasGathered ?? false;
    const error = props.state.error ?? false
    const errorMessage = props.state.errorMessage ?? ""

    useEffect(() => {
        //  analyticsOfPage("landing");
         window['getDataModel']('landing')
         window['getEvent']("landing");

        if( localStorage.getItem('splashcheck') != 'true')
        {
            history.push('/')
        }
        else{
       
       if( localStorage.getItem('onclickrestartDiagnostic') == 'true')
       {
           props.setState({
            hasGathered : false,
            termsAccepted:false,
            isAccepted:false
           })
         //  localStorage.removeItem('onclickrestartDiagnostic')
       }


           else{
               if(hasGathered === true) return
           }
 
        
       var environmentUrl = environmentCheck();
       let headerValue = new Headers();
       headerValue.append('Content-Security-Policy', `default-src 'self'`);
       headerValue.append('X-Frame-Options', 'DENY');
       headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
       headerValue.append('X-XSS-Protection', '1');
       headerValue.append('X-Content-Type-Options','nosniff');
        fetch(`${environmentUrl}/v1.0/generate-custom-token`,{headers: headerValue}
       )
       .then(v => {
        if(v.ok === true){
            return v.json()
        }
        else{
            throw "Error fetching data"
        }
    })
             .then(v => {
                
                 if(v.resultCode === "0"){
                     sessionStorage.setItem('accessToken', v.resultObject.customToken);
                     sessionStorage.setItem('transId', v.resultObject.transactionId);
                     landingdropdown();
                 }
                 else{
                     throw "Error on data"
                 }
                
             })
            
             .catch(r => {
                //  alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue."); 
                console.log(r)
             })


    
        }        }, [])

    function landingdropdown(){
      
        var environmentUrl = environmentCheck();
            let headerValue = new Headers();
            headerValue.append('Authorization', sessionStorage.getItem('accessToken'));
            headerValue.append('Content-Security-Policy', `default-src 'self'`);
            headerValue.append('X-Frame-Options', 'DENY');
            headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
            headerValue.append('X-XSS-Protection', '1');
            headerValue.append('X-Content-Type-Options','nosniff');
            headerValue.append('txnId', sessionStorage.getItem('transId'));
            headerValue.append('applName', 'survey-ms');
                
                  fetch(`${environmentUrl}/v1.0/dropdowns`,{headers: headerValue}
                )
                      .then(v => {
                          if(v.ok === true){
                              return v.json()
                          }
                          else{
                              throw "Error fetching data"
                          }
                      })
                      .then(v => {
                          const data = v as SrvResponse<IndustryTypeOptions>
                          if(data.resultCode === "0")
                              return data.resultObject!
                              if(data.resultCode === "1")
                              alert("You are not authorized to perform this action")
                          else
                              throw "Error on data"
                      })
                      .then(v => {
                          const data = v
                          localStorage.setItem('submitclick' ,'false')
                          localStorage.setItem('surveycheck' ,'false')
                          localStorage.setItem('resultspagecheck' ,'false')

                          props.setState({ 
                              industries: {
                                  array: data.industries,
                                  idx: -1
                              },
                              geography: {
                                  array: data.geographies,
                                  idx: -1
                              },
                              seniority: {
                                  array: data.seniorityLevels,
                                  idx: -1
                              },
                              revenue: {
                                  array: data.sizes,
                                  idx: -1
                              },
                              perspective: {
                                //   array: data.perspectives,
                                  array: [
                                      {
                                        perspectiveId: 0,
                                        perspectiveValue: "I'm taking the Diagnostic from an individual perspective"
                                      },
                                      {
                                        perspectiveId: 1,
                                        perspectiveValue: "I'm taking the Diagnostic from an organizational perspective"
                                      }                                      
                                  ],
                                  idx: -1
                              },
                              hasGathered: true,
                              termsAccepted:false,
                              isAccepted:false,
                              companyName:''
          
                          })
                      })
                      .catch(r => {
                        //   alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                        alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue."); 
                        console.log(r)
                      })
            
    }

    function arrangeRevenue(){
        if(revenue.array.length > 0){  
            let filteredArray =  revenue.array.map(v => v.sizeValue);
            let revenueNAArray = _.filter(filteredArray, function(item) { return item === "N/A" });  
            if(revenueNAArray.length >0){
   let revenueArray = _.filter(filteredArray, function(item) { return item !== "N/A" });
   revenueArray.unshift("N/A");
  
  let filRevenueArray =  _.filter(revenue.array, function(item) { return item.sizeValue !== "N/A" });
    let filArray =  _.filter(revenue.array, function(item) { return item.sizeValue  == "N/A" });
    filRevenueArray.unshift(filArray[0]);  
 
    revenue.array = filRevenueArray;
    return revenueArray;
            }
             else {
                
                return filteredArray;
}
        }
else {
    return revenue.array.map(v => v.sizeValue);

 }
    }

    function arrangeIndustry(){
        if(industries.array.length > 0){  
            let filteredArray =  industries.array.map(v => v.industryValue);
            let revenueNAArray = _.filter(filteredArray, function(item) { return item === "Other" });  
            if(revenueNAArray.length >0){
   let revenueArray = _.filter(filteredArray, function(item) { return item !== "Other" });
   revenueArray.push("Other");
  
  let filRevenueArray =  _.filter(industries.array, function(item) { return item.industryValue !== "Other" });
    let filArray =  _.filter(industries.array, function(item) { return item.industryValue  == "Other" });
    filRevenueArray.push(filArray[0]);  
 
    industries.array = filRevenueArray;
    return revenueArray;
            }
             else {
                
                return filteredArray;
}
        }
else {
    return industries.array.map(v => v.industryValue);

 }
    }


    return <Frame 
        screenProps={{
            companyName,
            onCompanyChangeClicked:  companyName => {
                props.setState({companyName})
                const validationRule =  /^[A-Za-z0-9 ]+$/
              
                let textValid = validationRule.test(companyName) ? true : false;
                
                if(companyName !== '' && textValid == false){  
                    props.setState({
                        error :true ,
                    errorMessage :  "Organization name should contain letters or numbers only",
                    companyName : ''
                }) 
                }
               
           
                else if(companyName && companyName.charAt(companyName.length-1).trim() == "" ) {
                    props.setState({
                        //error :true ,
                        companyName: companyName.trim()
                       // errorMessage : "Organization Name should contain at least one letter or number and should not begin or end with spaces."}); 
                    })   }
              
           },
            onCompanyNameChanged: companyName => {
                props.setState({companyName})
              
           
                if(companyName && companyName.trim() ==""){
                        
                    props.setState({error :true ,
                        errorMessage : "Organization Name should contain at least one letter or number and should not begin or end with spaces."}); 
                }
                else  if(companyName && (companyName.charAt(0) == ""  || companyName.charAt(companyName.length-1) == "" ) ){
                    props.setState({error :true ,
                        errorMessage : "Organization Name should contain at least one letter or number and should not begin or end with spaces."}); 
                }
                else if(companyName && companyName.charAt(companyName.length-1).trim() != "" ) {
                    props.setState({
                        error :false ,
                        errorMessage : "",
                        companyName: companyName.trim()
                       // errorMessage : "Organization Name should contain at least one letter or number and should not begin or end with spaces."}); 
                    })  
                 }
                else{
                    props.setState({error :false ,
                        errorMessage : "",
                        companyName: companyName
           
                    });
                }
           },
            industries: arrangeIndustry(),
            idxIndustriesSelected: industries.idx,
            setIndustries: (idx) => props.setState({industries: {...industries, idx}}),

            geography: geography.array.map(v => v.geographyValue),
            idxGeographySelected: geography.idx,
            setGeography: (idx) => props.setState({geography: {...geography, idx}}),

            seniorities: seniority.array.map(v => v.seniorityValue),
            idxSenioritiesSelected: seniority.idx,
            setSeniorities: (idx) => props.setState({seniority: {...seniority, idx}}),

            perspectives: perspective.array.map(v => v.perspectiveValue),
            idxPerspectivesSelected: perspective.idx,
            setPerspectives: (idx) => props.setState({perspective: {...perspective, idx}}),

            revenues: arrangeRevenue(),
            idxRevenuesSelected: revenue.idx,
            setRevenues: (idx) => props.setState({revenue: {...revenue, idx}}),
           error: error,
           errorMessage: errorMessage,
           isTermAccepted: termAccepted,
           onTermChanged: termsAccepted => props.setState({termsAccepted}),
           isAccepted: isAccepted,
           hasGathered :hasGathered,
           onClicked: isAccepted => props.setState({isAccepted}),
           isStartSurveyDisabled: (
               !((industries.idx !== -1) &&
               (geography.idx !== -1) &&
               (revenue.idx !== -1) &&
               (seniority.idx !== -1) &&
               (perspective.idx !== -1) &&
               termAccepted === true && isAccepted === true  )
           ),
           onStartSurveyClicked: () => {
             setTimeout(() =>  {
                localStorage.setItem('submitclick' ,'true')
                history.push("/survey",{data : props.state})},1000)
           }
        }}
        footerProps={{
            isTermAccepted: termAccepted,
            onTermChanged: termsAccepted => props.setState({termsAccepted}),
            isStartSurveyDisabled: (
                !((industries.idx !== -1) &&
                (geography.idx !== -1) &&
                (revenue.idx !== -1) &&
                (seniority.idx !== -1) &&
                (perspective.idx !== -1) &&
                termAccepted === true)
            ),
            onStartSurveyClicked: () => {
             
              setTimeout(() =>  {
                 
                  history.push("/survey",{data : props.state})},1000)
            }
        }}/>
}

