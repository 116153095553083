function getSize() {
    var wWidth = $(window).width();
    var sbWidth = window.innerWidth - document.documentElement.clientWidth;
    var vpWidth = wWidth + sbWidth;
    if (vpWidth >= 480 && vpWidth <= 767) {
        return "xs"
    } else if (vpWidth >= 768 && vpWidth <= 999) {
        return "sm"
    } else if (vpWidth >= 1000) {
        return "lg/md"
    }
}

export default function analyticsOfPage(pageID) {
    // The dataModel object is the source of information for some page view and site information metrics
    var dataModel = {
        "page": {
            "pageInfo": {
                "country": "us",
                "language": "en",
                "pageName": `sbo:${pageID}`, // see guideline #2 below on how to set this up
                "pageShortURL": "",
                "publishDate": "",
                "pageType": "",
                "responsiveLayout": getSize(),
                "siteID": "sbo", // CIO team will supply this value depending on the site
                "siteBranch": "diagnostic", // CIO team will supply this value as well
                "subFolder": null,
                "tertiaryFolder": null,
            },
        },
    }

    if(pageID==="download"){
        _satellite.track( 'soClick' , {name: 'DOWNLOAD YOUR REPORT' })
    }
    else{
        _satellite.track('soPV');
    }
}