import { Button } from "@material-ui/core";
import React from "react";

export interface ISurveyPageFooterProps{
    isSubmitDisabled: boolean;
    onSubmitClick: () => void
}

export default function SurveyPageFooter(props: ISurveyPageFooterProps){
    return <><div></div>
        </>{/* <div id="survey-footer">
        <Button
            variant="contained" 
            className="button container bg-white"
            classes={{
                label: "button-text"
            }}
            disabled={props.isSubmitDisabled} onClick={() => props.onSubmitClick()}>SUBMIT</Button>
    </div> */}
}