import React, { useEffect, useState } from "react";
import IAnsweredQuestion from "../../Common/IAnsweredQuestion";
import { withCommonScreen } from "../../Components/WithCommonScreen";
import SurveyPageContent from "./SurveyPageContent";
import SurveyPageFooter from "./SurveyPageFooter";
import ICommonStateProps from "../../Common/ICommonStateProps";
import SrvResponse from "../../Common/DTO/SrvResponse";
import Question from "../../Common/DTO/Question";
import { useHistory } from "react-router-dom";
import analyticsOfPage from "../../Components/AdobeAnalytics";

const Frame = withCommonScreen(SurveyPageContent, SurveyPageFooter)

export interface ISurveyPageState{
    questions: IAnsweredQuestion[]
}

function environmentCheck(){
    if (process.env.REACT_APP_BASE_URL === 'production') {
        return (`https://kwnruy0k79.execute-api.us-east-1.amazonaws.com/prod-sus/rla`)
    }
    if (process.env.REACT_APP_BASE_URL === 'staging') {
        return (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/uat/rla`)
    }
    if (process.env.REACT_APP_BASE_URL === 'development') {
        return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev/rla`)
    }
    else{
        return  (`https://ur9wf4cfm5.execute-api.us-east-1.amazonaws.com/dev/rla`)
    }
}
export default function SurveyPage(props: ICommonStateProps<ISurveyPageState>){

    const { state, setState } = props

    const history = useHistory()
    useEffect(() => { 
        //  analyticsOfPage("survey");
        window['getDataModel']('survey')
        window['getEvent']("survey")

        if( localStorage.getItem('splashcheck') != 'true')
        {
            history.push('/')
        }
       else if( localStorage.getItem('submitclick') != 'true')
        {
            history.push('/landing')
        }
        else{
        
       if( localStorage.getItem('onclickrestartDiagnostic') == 'true' &&
                state.questions !== undefined && state.questions.length > 0){
                    setState({
                        questions:[]
                    })
          /*   setState({
                questions: state.questions
                .map(v => ({
                    id: v.id,
                    idEnabler: v.idEnabler,
                    question: v.question,
                    questionSeqNum: v.questionSeqNum,
                    scale : undefined
                })) 
            }) */
            
            localStorage.removeItem('onclickrestartDiagnostic')   

        }
        /* const questionFak = makeFactory<IAnsweredQuestion>({
            id: each(i => i),
            idEnabler: each(i => i),
            question: each(() => faker.random.words(30)),
            questionSeqNum: each(i => i + 1)
        })
        setQuestions(questionFak.buildList(10)) */
else{
        if(state.questions !== undefined && state.questions.length > 0){
            return
        }
    }
        var environmentURL = environmentCheck();
    let headerValue = new Headers();

    headerValue.append('Authorization', sessionStorage.getItem('accessToken')); 
    headerValue.append('Content-Security-Policy', `default-src 'self'`);
    headerValue.append('txnId', sessionStorage.getItem('transId'));
    headerValue.append('applName', 'survey-ms');
    headerValue.append('X-Frame-Options', 'DENY');
    headerValue.append('Strict-Transport-Security', `max-age=31536000;includeSubDomains; preload`);
    headerValue.append('X-XSS-Protection', '1');
    headerValue.append('X-Content-Type-Options','nosniff');

        fetch(`${environmentURL}/v1.0/questions`,{headers :headerValue})
            .then(v => {
                if(v.ok === true){
                    return v.json()
                }
                else{
                    throw "Error fetching data"
                }
            })
            .then(v => {
                const data = v as SrvResponse<Question[]>
                if(data.resultCode === "0")
                    return data.resultObject!
                     else if(data.resultCode === "1"){
                        alert("You are not authorized to perform this action");
                        } 
                else
                    throw "Error on data"
            })
            .then(v => {
                const data = v
                 localStorage.setItem('surveycheck' ,'false')
                 localStorage.setItem('resultspagecheck' ,'false')

                props.setState({ 
                    questions: data.map(v => ({
                        id: v.questionId,
                        idEnabler: v.enablerId,
                        question: v.question,
                        questionSeqNum: v.questionSeqNo
                    }))
                })
            })
            .catch(r => {
        // alert("Sorry, there's been an error. Please try again and if you receive this message again, please contact sustainableorg@accenture.com for technical support.");
                alert("For security reasons, your current session has timed out. Sorry about this - please restart the diagnostic to continue.");   
                console.log(r)
            })
     } }, [])

    
    // React.useEffect(() => {
    //     var isIE = /*@cc_on!@*/false || !!document.documentMode;
    //     if (isIE){
    //         window.location.reload();
    //     }
    // }, [])

    const onQuestionChanged = (newQuestion: IAnsweredQuestion, idx: number) => { 
        const newQuestions = [...state.questions ?? []]
        newQuestions[idx] = newQuestion
        setState({questions: newQuestions})
    }

const submitClick = () =>{
    localStorage.setItem('surveycheck' ,'true')
    let companyArray = {};
    const companyDetails = history.location.state ? history.location.state.data  :  null;
if(companyDetails)
    history.push("/results",{data :companyDetails ,questionData : state.questions})
   else{
   alert('Please take up survey again')
   }
}
    
    return <Frame 
        footerProps={{
            isSubmitDisabled: (state.questions ?? []).filter(v => v.scale === undefined).length !== 0,
            onSubmitClick: () => history.push("/results")
        }}
        screenProps={{
            questions: state.questions ?? [],
            onQuestionChanged,
            isSubmitDisabled: (state.questions ?? []).filter(v => v.scale === undefined).length !== 0,
            onSubmitClick: submitClick
        }}
    />
}