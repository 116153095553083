import { createStyles, FormControl, Grid, Hidden, InputLabel, makeStyles, MenuItem, NativeSelect, Select, TextField, Tooltip } from '@material-ui/core'
import React, { useState ,useEffect} from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Modal } from '../../Components/Modal/Modal';
import { ConfirmationModal } from '../../Components/CustomConfirmModal/CustomConfirmModal';
import { useHistory } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { useModal } from './useModal';
import grey from "@material-ui/core/colors/green";
import { FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import * as _ from "lodash";
import logo from "../../public/img/accenture_logo_new.svg";
import infoIcon from "../../public/img/info_icn.svg";

interface IDefaultSelectProps{
    data: string[]
    idx: number
    label: string
    defaultSelectedText: string
    onChange: (newIdx: number) => void
}

function DefaultSelect(props: IDefaultSelectProps){

    const classes = makeStyles(t => createStyles({
        select: {
            '& svg': {
                top: 'calc(50% - 0.75rem)'
            },            
            '& fieldset': {
                borderColor: 'white',
                borderWidth: 2,
            },
            '& input:hover + fieldset': {
                borderColor: 'white',
                borderWidth: 2,
            }
        },
        container: {
            paddingRight: '5px'
        },
        innerContainer: {
            padding: "10px 2px 15px 12px",
            fontFamily : "Graphik-Regular",
            fontColor: "#FFFFFF",
            fontStyle: "400",
            fontSize:'0.9rem',
            letterSpacing:'0.01106vh',
            opacity:'0.8',
            lineHeight: 'normal'

        },
         innerContainerSelect: {
            padding: "10px 2px 15px 12px",
            fontFamily : "Graphik-Regular",
            fontColor: "#FFFFFF",
            fontStyle: "400",
            fontSize:'0.9rem',
            letterSpacing:'0.01106vh',
            opacity:'100%',
            lineHeight: 'normal'
        }
    }))()
    
    return <Grid container className={classes.container}>
        <Grid item xs={12}>
        <label className="bodyText instructions select-label">{props.label}</label>
        </Grid>
        <Grid item xs={12}>
        <Select
            className={`${classes.select} select`}
            style={{
                color: "white",
                outlineColor: "white",
                borderColor: "white",
                opacity: "0.8",
                borderRadius:'0%',
              //  width:'18.229166vw',
              //  minWidth:'13.02083vw',
                height:'2.5rem',
                
            }}
            classes={{
                select: (props.idx == -1 ? classes.innerContainer : classes.innerContainerSelect)
            }}
            fullWidth
            variant="outlined"
            value={props.idx}
            onChange={({target: {value}}) => {
                props.onChange(parseInt(value as string))
            }}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                MenuListProps: {
                    style: {
                        maxHeight: "12.375rem",
                        overflowY: "auto",
                        borderRadius:'0%'
                    }
                }
            }}
            inputProps={{
                name: 'age',
                id: 'age-native-label-placeholder',
            }}
        >
            <MenuItem style={{display:"none"}} value={-1}>{props.defaultSelectedText}</MenuItem>
            {
                props.data.map((v,i) => <MenuItem key={i.toString()} value={i} className={"option"}>{v}</MenuItem>)
            }
        </Select>
        </Grid>
    </Grid>

}

export interface ILandingPageContentProps{
    emailValidation: (t: string) => void;
    onCancel: () => void;
    errorMessage: string;
    error: boolean 
    industries: string[]
    setIndustries: (idx: number) => void
    idxIndustriesSelected: number
    geography: string[]
    setGeography: (idx: number) => void
    idxGeographySelected: number
    seniorities: string[]
    setSeniorities: (idx: number) => void
    idxSenioritiesSelected: number
    perspectives: string[]
    setPerspectives: (idx: number) => void
    idxPerspectivesSelected: number
    revenues: string[]
    setRevenues: (idx: number) => void
    idxRevenuesSelected: number
    companyName: string
    onCompanyNameChanged: (t: string) => void
    onCompanyChangeClicked: (t: string) => void
    isTermAccepted: boolean
    onTermChanged: (isSelected: boolean) => void
    isStartSurveyDisabled: boolean
    onStartSurveyClicked: () => void
    userNameChanged: (t: string) => void;
    userName:string,
    hasGathered:boolean,
    
  isAccepted: boolean
  onClicked: (t: boolean) => void;

}

//Checkbox
const WhiteCheckbox = withStyles({
    root: {
        color: grey[50],
        '&$checked': {
            color: grey[50],
        },
        transform: "scale(1.2)",
        marginLeft: '4px',
        marginRight: '0.8rem'
    },
    checked: {},
})(Checkbox); 

export default function LandingPageContent(props: ILandingPageContentProps) {
    const { isShown, toggle } = useModal();
   /* useEffect(() => {
        if( document.getElementById('newdiv')){
            //window.scrollTo(0,0);  
            document.getElementById('newdiv').scrollTop = 0;
        }
    },[props.hasGathered]);  */

    const classes = makeStyles(t => createStyles({
        input: {
            color: "white",
            // height: '40px',
            '& input': {
                color: "white !important"
            },
            '& .MuiInputBase-root': {
                borderColor: 'white',
                borderWidth: 2,
                borderRadius:'0%',
                opacity: 0.8,
                zIndex: '10'
                
            },
            '& fieldset': {
                borderColor: 'white',
                borderWidth: 2,
                borderRadius:'0%'
            },
            '& input:hover + fieldset': {
                borderColor: 'white',
                borderWidth: 2,
                borderRadius:'0%'
            },
            
        },
        inputInnerPadding: {
            padding: "0.625rem 0.125rem 0.688rem 0.75rem;",
            fontFamily : "Graphik-Regular !important",
            fontColor: "#FFFFFF !important",
            fontStyle: "400 !important",
            fontSize:'1rem !important',
            letterSpacing:'0.17px !important'
            
        },
        inputContainerMargin: {
            marginTop: `25px`
        },
        inputSpacing: {
            paddingRight: '5px'
        },

        xscenterMargin: {
            [t.breakpoints.down('xs')]:{
                marginLeft:'-15px'
            }
        },
        endText:{
            fontFamily: "Graphik-Regular",
            fontSize: "0.875rem",
color: '#FFFFFF',
letterSpacing: '0.011rem',
lineHeight: '1.125rem',
fontStyle: '400',
        },
        endTextContainer:{
            [t.breakpoints.up('xs')]:{
                marginLeft:'-2rem'
            },
            [t.breakpoints.down('xs')]:{
                marginLeft:'0rem'
            }
        },
        paragraph: {
            color: "white",
            fontFamily:'Graphik-Regular',
            fontSize:'1.3rem',
            // width: '75%',
            // [t.breakpoints.down('xs')]: {
            //     width: '100%'
            // }
        },
        labelPadding: {
            alignItems: 'flex-start',
            '& span:nth-child(2)': {
                paddingTop: '0.313rem'
            }
        }
    
    }))()

    return (
    //     <>
    //     <div>
    //         <h1 className="title">Welcome to the Leading Responsible Organisations Diagnostic​</h1>

    //         <p className="bodyText attention">Please start by telling us a bit more about you and your organisation, this will enable you to benchmark your responses against similar organisations in the index</p>
            
    //         <p className="bodyText instructionNew">All fields marked with * are mandatory</p>
    //     </div>

    //     <Grid container style={{    width: '1110px'}}>
    //         <Grid item xs={12} sm={6} md={3} className={classes.inputContainerMargin}>
    //         <DefaultSelect idx={props.idxIndustriesSelected} 
    //             data={props.industries} 
    //             defaultSelectedText="Select" 
    //             label="Industry*"
    //             onChange={(v) => {
    //                 props.setIndustries(v)
    //             }}
    //         />
    //         </Grid>
    //         <Grid item xs={12} sm={6} md={3} className={classes.inputContainerMargin}>
    //         <DefaultSelect idx={props.idxGeographySelected} 
    //             data={props.geography} 
    //             defaultSelectedText="Select" 
    //             label="Geography*"
    //             onChange={(v) => {
    //                 props.setGeography(v)
    //             }}
    //         />
    //         </Grid>
    //         <Grid item xs={12} sm={6} md={3} className={classes.inputContainerMargin}>
    //         <DefaultSelect idx={props.idxRevenuesSelected} 
    //             data={props.revenues} 
    //             defaultSelectedText="Select" 
    //             label="Size*"
    //             onChange={(v) => {
    //                 props.setRevenues(v)
    //             }}
    //         />
    //         </Grid>
          
            
    //     </Grid>
    //     <Grid container>
    //     <Grid item xs={12} sm={6} md={3} className={classes.inputContainerMargin}>
    //         <DefaultSelect idx={props.idxSenioritiesSelected} 
    //             data={props.seniorities} 
    //             defaultSelectedText="Select" 
    //             label="Your Seniority*"
    //             onChange={(v) => {
    //                 props.setSeniorities(v)
    //             }}
    //         />
    //         </Grid>
    //     </Grid>
    //     <Grid container>
    //         <Grid container item xs={12} sm={6} className={`${classes.inputSpacing} ${classes.inputContainerMargin}`} md={4}>
    //             <Grid item xs={12}>
    //                 <label className="bodyText instructions select-label">Organisation Name</label>
    //             </Grid>
    //             <Grid item xs={12}>
    //             <TextField 
    //                 className={`${classes.input}`}
    //                 placeholder="Enter"
    //                 error={props.error}
    //                 variant="outlined"
    //                 fullWidth
    //                 inputProps={
    //                     {
    //                         className: `${classes.inputInnerPadding} textField-font`,
    //                         maxLength: 50
    //                     }                   
                    
                        
    //                 }
    //                 helperText={props.errorMessage }   
    //                 value={props.companyName}
    //                 onChange={({target: {value}}) => props.onCompanyNameChanged(value)}
    //              onBlur={({target: {value}}) =>props.onCompanyChangeClicked(value)}
    //             />
    //             </Grid>
    //         </Grid>
    //         <Grid item xs={12} sm={4} md={3}>
    //         </Grid>
    //     </Grid>
    //    <Grid container style={{textAlign: "left",paddingTop :'6.4%'}}>
    //         <Grid item xs={12} >
    //         <FormGroup >
    //             <FormControlLabel style={{margin: "unset" }}
    //                 control={
    //                 <WhiteCheckbox
    //                     checkedIcon={<CheckBoxOutlinedIcon/>}
    //                     checked={props.isTermAccepted}
    //                     onChange={({target:{checked}}) => props.onTermChanged(checked)}
    //                     name="terms"
    //                 />
    //                 }
    //                 label={<label id="terms-font" >By clicking 'Start Survey', I agree to the <a style ={{color:"#FFFFFF"}} target='_blank' href='https://www.accenture.com/us-en/support/terms-of-use'> Terms of Use</a> and  <a style ={{color:"#FFFFFF"}} target='_blank' href='https://www.accenture.com/us-en/about/privacy-policy'>Privacy Policy</a></label>}
    //             />
    //         </FormGroup>
    //         </Grid>
          
              
           
                   
    //        <Grid  item xs={12} style={{paddingLeft:'500px', paddingTop :'2.3%'}}>
    //         <Button
    //             variant="contained" 
    //             className="button container bg-white"
    //             classes={{
    //                 label: "button-text"
    //             }}
    //             disabled={props.isStartSurveyDisabled} 
    //             onClick={toggle}>START SURVEY</Button>
    //         </Grid>
            
    //         </Grid>
            
    
    //      <Modal
    //       isShown={isShown}
    //       hide={toggle}
    //       page="Landing"
    //       headerText='Please check the box below to proceed.'
    //       modalContent={
    //         <ConfirmationModal 
    //         onStartSurveyClicked = {props.onStartSurveyClicked}
    //           message='I’m not a robot'
    //           page="Landing"
    //           termsAccepted={props.isTermAccepted}
    //           onTermChanged ={props.onTermChanged}
    //           userNameChanged={props.userNameChanged}
    //            isStartSurveyDisabled ={props.isStartSurveyDisabled}
    //            userName ={props.userName}
    //         />
    //       }
    //     />
     
   
       
       
    //     </>
    <Grid container className = {classes.xscenterMargin}>
         <Grid container md={12} sm={12} xs={12} style = {{marginTop:"2rem"}}>
            <Typography variant="h4"  gutterBottom style = {{color: "white",fontFamily:'Graphik-Bold',fontSize:'2.0rem'}}>
                Welcome to the Sustainable Organizations Diagnostic
            </Typography>
        </Grid>
        <Grid container md={10} sm={10} xs={12} style = {{marginTop:"1.5rem"}}>
            <Typography variant="h6"  gutterBottom className = {classes.paragraph}>
            Please start by telling us a bit more about you and your organization, which will enable you to benchmark your scores against similar organizations in our Index.
             </Typography>
        </Grid>
        <Grid container md={12} sm={12} xs={12} style = {{marginTop:"1rem"}}>
            <Typography variant="subtitle1"  gutterBottom style = {{color: "white",fontFamily:'Graphik-Medium',fontSize:'0.9rem'}}>
                All fields marked with * are mandatory
            </Typography>
        </Grid>
        <Grid container md={10} sm={10} xs={12} spacing= {3}>
            <Grid item md={4} sm={6} xs={12}>
                <DefaultSelect idx={props.idxIndustriesSelected} 
                        data={props.industries} 
                        defaultSelectedText="Select" 
                        label="Industry*"
                        onChange={(v) => {
                            props.setIndustries(v)
                        }}
                    />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <DefaultSelect idx={props.idxGeographySelected} 
                data={props.geography} 
                defaultSelectedText="Select" 
                label="Geography*"
                onChange={(v) => {
                    props.setGeography(v)
                }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
                <DefaultSelect idx={props.idxRevenuesSelected} 
                data={props.revenues} 
                defaultSelectedText="Select" 
                label="Annual Revenue*"
                onChange={(v) => {
                    props.setRevenues(v)
                }}
            />
         </Grid>
         <Grid item md={4} sm={6} xs={12}>
                    <DefaultSelect idx={props.idxSenioritiesSelected} 
                    data={props.seniorities} 
                    defaultSelectedText="Select" 
                    label="Your Career Level*"
                    onChange={(v) => {
                        props.setSeniorities(v)
                    }}
                /> 
         </Grid>
         <Grid item md={4} sm={6} xs={12}>
                    <DefaultSelect idx={props.idxPerspectivesSelected} 
                    data={props.perspectives} 
                    defaultSelectedText="Select" 
                    label={<label className="bodyText instructions select-label" style={{ whiteSpace: "normal", marginBottom: "0", display: "flex", justifyContent: "space-between", alignItems: "center"}}>Your perspective*
                                <Tooltip disableFocusListener leaveTouchDelay={5000} placement="top" enterTouchDelay={0} title="We invite you to try the diagnostic from both a personal and organizational perspective to compare how your values may align or differ.">
                                    <img src={infoIcon} alt="i" style={{ width: "1rem"}}/>
                                </Tooltip>
                            </label>}
                    onChange={(v) => {
                        props.setPerspectives(v)
                    }}
                /> 
         </Grid>

         <Grid item md={4} sm={6} xs={12} style={{paddingRight:'17px'}}>
            <label className="bodyText instructions select-label">Organization Name (Optional)</label>
            <TextField 
                        className={`${classes.input}`}
                        placeholder="Enter"
                        error={props.error}
                        variant="outlined"
                        fullWidth
                        inputProps={
                            {
                                className: `${classes.inputInnerPadding} textField-font`,
                                maxLength: 50
                            }                   
                        
                            
                        }
                        helperText={props.errorMessage }   
                        value={props.companyName}
                        onChange={({target: {value}}) => props.onCompanyNameChanged(value)}
                    onBlur={({target: {value}}) =>props.onCompanyChangeClicked(value)}
                    />
         </Grid>
         
        
     </Grid>
        {/* <Grid container md={12} sm={12} xs={12} style = {{marginTop:"1rem"}}>
        <Grid item md={3} sm={6} xs={12}>
                    <DefaultSelect idx={props.idxSenioritiesSelected} 
                    data={props.seniorities} 
                    defaultSelectedText="Select" 
                    label="Your Seniority*"
                    onChange={(v) => {
                        props.setSeniorities(v)
                    }}
                />
         </Grid>
         
        <Grid item md={6} sm={6} xs={12}>
            <label className="bodyText instructions select-label">Organization Name</label>
            <TextField 
                        className={`${classes.input}`}
                        placeholder="Enter"
                        error={props.error}
                        variant="outlined"
                        fullWidth
                        inputProps={
                            {
                                className: `${classes.inputInnerPadding} textField-font`,
                                maxLength: 50
                            }                   
                        
                            
                        }
                        helperText={props.errorMessage }   
                        value={props.companyName}
                        onChange={({target: {value}}) => props.onCompanyNameChanged(value)}
                    onBlur={({target: {value}}) =>props.onCompanyChangeClicked(value)}
                    />
         </Grid>
        </Grid> */}
        <Grid container item md={10} xs={11} sm={10}  style = {{marginTop:"1.5rem", overflowWrap: "anywhere"}}>
           
                <FormGroup style={{flexDirection: 'row'}}>
                    <FormControlLabel
                        className={classes.labelPadding}
                        control={
                        <WhiteCheckbox
                        size="medium"
                            checkedIcon={<CheckBoxOutlinedIcon/>}
                            checked={props.isTermAccepted}
                            onChange={({target:{checked}}) => props.onTermChanged(checked)}
                            name="terms"
                        />
                        }
                        label={<label id="terms-font" >   I hereby agree that Accenture processes my personal data in accordance with Accenture’s <a style ={{color:"#FFFFFF",fontSize:'0.875rem'}} target='_blank' href='https://www.accenture.com/us-en/about/privacy-policy'>privacy policy</a> to create an individualized report on Sustainable Organizations, based on my input. You can withdraw your consent at any time by sending an email to  <a  target='_blank' style ={{color:"#FFFFFF",fontSize:'0.875rem'}}  href="mailto:dataprivacyofficer@accenture.com">dataprivacyofficer@accenture.com</a> mentioning this survey.* </label>}
           
              />
                </FormGroup>
                <FormGroup >
                    <FormControlLabel
                        control={
                        <WhiteCheckbox
                            checkedIcon={<CheckBoxOutlinedIcon/>}
                            checked={props.isAccepted}
                            onChange={({target:{checked}}) => props.onClicked(checked)}
                            name="terms"
                        />
                        }
                        label={<label id="terms-font" >I agree with Accenture's <a style ={{color:"#FFFFFF",fontSize:'0.875rem'}} target='_blank' href='https://www.accenture.com/us-en/support/terms-of-use'>terms of use</a>.*  </label>}        
                            />
                </FormGroup>
        </Grid>
        <Grid container justify = "center" style = {{marginTop:'1rem',marginBottom:'1rem'}}>
             <Button
                variant="contained" 
                className="button container bg-white"
                classes={{label:"button-text"}}
                disabled={props.isStartSurveyDisabled} 
             /*  onClick={props.onStartSurveyClicked}>START SURVEY</Button>  */

                onClick={toggle}>START DIAGNOSTIC</Button>

        </Grid>
        <Grid container justify = "center" md={12} sm={12} xs={12}
             className={classes.endTextContainer}
            style = {{marginTop:'1rem', marginBottom:'1.5rem'}}>
             <Grid item md={10} style = {{marginRight:'0.5rem'}}>
                <Typography className={classes.endText}>
                    This Diagnostic benchmarks your perception of your Sustainability DNA against Accenture's dataset of ~400,000 external indicators of sustainable organization practices, giving an indication of the strength of your Sustainability DNA. This does not constitute formal consulting advice.</Typography>
            </Grid>
        </Grid>
        <Modal
          isShown={isShown}
          hide={toggle}
          headerText='Please check the box below to proceed.'
          page="Landing"
          showPopup ={false}
          showConfirmPopup ={false}
          modalContent={
            <ConfirmationModal 
            onStartSurveyClicked = {props.onStartSurveyClicked}
              message='I’m not a robot'
              page="Landing"
              termsAccepted={false}
              isAccepted={false}
              onClicked ={props.onClicked}
              onTermChanged ={props.onTermChanged}
              userNameChanged={props.userNameChanged}
               isStartSurveyDisabled ={props.isStartSurveyDisabled}
               userName = ""
               roleName =""
               seniority =""
               organisationName =""
               emailAddress = ""
               errorMessage = ""
               emailValidation ={props.emailValidation}
               onCancel={props.onCancel}
            />
          }
        />
       
    </Grid>
    )
}
