import { Box, Button, createStyles, Grid, Hidden, LinearProgress, LinearProgressProps, Typography, withStyles, Tooltip } from "@material-ui/core";
import React from "react";
import IAnsweredQuestion from "../Common/IAnsweredQuestion";
import QuestionComponent from "./QuestionComponent";

// const QuestionProgressBar = function(props: LinearProgressProps & { value: number }){


//     const StyledLinearProgress = withStyles(t => createStyles({
//         root: {
//             height: "1.85676vh",
//             borderRadius: "7px",
//             backgroundColor: "#794089"
//         },
//         bar:{
//             backgroundColor: '#3EDD7B',
//             borderRadius: '1px',
//             opacity: 0.8,
//             transform:'rotate(-270deg)'

//         }
//     }))(LinearProgress)

//     return <Box display="flex" alignItems="center">
//         <Box width="90%" mr={1}>
//             <StyledLinearProgress
//                 variant="determinate" 
//                 {...props}
//             />
//         </Box>
//         <Box minWidth={35}>
//             <p className="bodyText">{`${Math.round(props.value)}%`}</p>
//         </Box>
//     </Box>
// }

export interface IQuestionContainerProps{
    onSubmitClick(): void;
    isSubmitDisabled: boolean ;
    questions: IAnsweredQuestion[]
    onQuestionChanged: (newQuestion: IAnsweredQuestion, idx: number) => void
}

export default function QuestionContainer(props: IQuestionContainerProps){ 

    return <>
        <Grid container className = "quest-container">
        {/* <div className = "quest-container"> */}
            <div style={{width: '100vw', display: 'block'}}>
                {props.questions.map((v, i) => <QuestionComponent
                    key={i.toString()}
                    question={v}
                    onQuestionChanged={(n) => props.onQuestionChanged(n, i)} />
                )}
                {props.questions && props.questions.length >1  ? 
                <Tooltip placement="top" disableHoverListener={!props.isSubmitDisabled} disableTouchListener={!props.isSubmitDisabled} enterTouchDelay={0} title="Please answer all questions to Calculate Score">
                <div id="survey-footer">
                    <Button
                    variant="contained"
                    className="button container bg-white"
                    classes={{
                        label: "button-text"
                    }}
                    disabled={props.isSubmitDisabled} onClick={() => props.onSubmitClick()}>Calculate score</Button>
                </div>
                </Tooltip>
                : 
                "" }
            </div>
            </Grid>
    </>
}