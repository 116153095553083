import React, { FunctionComponent, useEffect } from "react";
import FocusLock from "react-focus-lock";
import ReactDOM from "react-dom";
import CloseIcon from '@material-ui/icons/Close';

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  showConfirmPopup
  Content,
  Backdrop,
  StyledNewModal
} from "./Modal.style";
import { PropertyDescriptorParsingType } from "html2canvas/dist/types/css/IPropertyDescriptor";
import { Button, IconButton } from "@material-ui/core";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
  headerText: string;
  page :string;
  showPopup:boolean;
  showConfirmPopup: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
  page,showPopup,showConfirmPopup
}) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27 && isShown) {
      hide();
    }
  };

  const onCancel = (event: any) => {
    hide();
  
  }

  useEffect(() => {
    isShown 
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isShown]);

  const modal = (
    <React.Fragment>
      <Backdrop onClick={hide} />
      <FocusLock>
        <Wrapper
          aria-modal
          aria-labelledby={headerText}
          tabIndex={-1}
          role="dialog"
        >
         
          {page === 'Results' ?    <>
          <StyledNewModal><Header style={{ padding:'0%',marginTop:'-1rem'}}><div className="reportHeaderText">{headerText}</div>
          <IconButton className="action" style={{ float: 'right', color:'black',padding: '0px ! important' ,marginTop: '-0.8rem', marginRight: '-1.2rem'}}
                          size="small" onClick={onCancel}
                        >
                          <CloseIcon   onClick={onCancel}/>
                        </IconButton></Header>
                        <div style={{fontSize:'0.6rem',fontFamily:'Graphik-Regular'}}>To download your diagnostic report and possibly be contacted regarding your results, please complete the form below.</div>
                        <div className="fieldText">*Required fields</div>
             
              <div> {modalContent}</div>
              </StyledNewModal></>
          : 
          (page == 'Success' ?    <>
          <StyledModal><Header style={{ padding:'2%'}}>
              <div className="confirmPopup" >{headerText}</div></Header>
           <div style={{ padding:'2%'}}>{modalContent}</div>
                </StyledModal></>
          : 
           <>
             <StyledModal><Header>
                <HeaderText>{headerText}               </HeaderText>

              </Header>
                <Content>{modalContent}</Content>
                </StyledModal></>)
  }
  
         
        </Wrapper>
      </FocusLock>
    </React.Fragment>
  );

  return isShown || showPopup  || showConfirmPopup ? ReactDOM.createPortal(modal, document.body) : null;
};
