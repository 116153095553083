import React, { useEffect } from 'react'
// import splashLogo from "../public/img/splashLogo.svg";
import WEFLogo from "../public/img/WEF_logo.svg";
import { useHistory } from "react-router-dom";
import {withStyles,Theme, createStyles,makeStyles
  } from '@material-ui/core/styles';
  import Button, { ButtonClassKey } from '@material-ui/core/Button';
  import { purple } from '@material-ui/core/colors';
  import Grid from '@material-ui/core/Grid';
  import Hidden from '@material-ui/core/Hidden';
  import logo from "../public/img/accenture_logo_new.svg";
  import Typography from '@material-ui/core/Typography';
import splashBg from "../public/img/splash_bkg_img.svg";
import { Footer } from "../Components/Footer";
import analyticsOfPage from "../Components/AdobeAnalytics";


  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    marginSplash:{
        // marginTop: '6rem',
        // [theme.breakpoints.down('md')]: {
        //     marginTop: '8rem'
        //  },
        // [theme.breakpoints.down('lg')]: {
        //     marginTop: '5rem'
        //  },
         [theme.breakpoints.down('sm')]: {
            marginTop: '2rem'
         },
        //  [theme.breakpoints.up('xl')]: {
        //     marginTop: '2rem'
        //  }
    },
    colorButton:{
        borderRadius: "1.333rem",
        padding: "0.333rem 0.889rem",
        paddingTop: "0.278rem",
        backgroundColor: "#A100FF",
        '&:hover': {
            backgroundColor: '#A100FF',
        },
        fontFamily:'Graphik-Bold',
        fontSize:'1.042rem! important',
        [theme.breakpoints.down('xs')]: {
            fontSize:'0.85rem! important'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize:'1.542rem! important'
        }
    },
    bgImage: {
        backgroundImage:  `url(${splashBg})`
    },
    paragraph: {
        // [theme.breakpoints.up('xl')]: {
        //     fontSize: "1.5rem", 
        //     lineHeight: '1.7rem',
        //     letterSpacing: '0.035rem' 
        // },
        marginLeft:"0rem",
        marginRight: "6rem",
        fontFamily: "Graphik-Regular",
        fontSize: ".85rem",   
        color: "#FFFFFF",
        letterSpacing: "0.013rem",
        lineHeight: "1.6rem",
        // fontWeight: "500",
        [theme.breakpoints.down('xs')]: {
           marginTop:'2rem',
           marginLeft: '1.563rem',
           marginRight:'1.563rem',
           fontSize: '1rem'
        }
    },
    splashText: {
        fontFamily: "Graphik-Bold",
        fontSize: "3rem",
        color: "#ffffff",
        letterSpacing: "0.15rem",
        lineHeight: "2.93rem",
        textAlign: "left",
        marginBlockStart: "0",
        marginBlockEnd: "0",
        marginBottom: "0",
        marginTop: "0",
        marginLeft: "6rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.5rem",
            lineHeight: "2.5rem",
            textAlign: 'center',
            fontWeight: "bolder",
            margin: '0 1rem',
            paddingTop: "2rem"
        }, 
        // [theme.breakpoints.up('xl')]: {
        //     fontSize: "3.5rem",
        //     lineHeight: "4rem",
        //     letterSpacing:'0.25rem'
        // }
        // ,
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: "2.5rem",
        //     lineHeight: "2.5rem",
        //     fontWeight: "bolder"
        // }, 
       
    },
    splashLogo: {
        width:'43rem',
        [theme.breakpoints.down('xs')]: {
           width: '20rem'
        }
    },
    logo: {
        marginBottom: '1rem',
        [theme.breakpoints.down('xs')]: {
           margin: '1rem 0'
        }
    },
    hyperlinks: { 
        color: "#FFFFFF",
        fontSize: '.85rem',
        textDecoration: "underline",
        fontFamily: "Graphik-Medium",
        [theme.breakpoints.down('xs')]: {
           fontSize: '1rem'
        }
    }
  }),
);


export default function SplashPage() {

    localStorage.setItem('splashcheck' ,'false')
    const classes = useStyles();
    let history = useHistory();

    // This is marked as a mistake because ButtonClassKey wants all properties to be declared, which I'm not doing now
    // If you errase the ButtonClassKey type definition, the ColorButton will mark error, and that really affects runtime
    const ColorButton = withStyles((theme: Theme) => createStyles<Partial<ButtonClassKey>, {}>({
        root: {
            color: theme.palette.getContrastText(purple[500]),
            primary: '#A100FF',
            backgroundColor: '#A100FF',
            '&:hover': {
                backgroundColor: '#A100FF',
            }
        }
    }))(Button);

    useEffect(() => {
        //  analyticsOfPage("splash");
         window['getDataModel']('splash');
         window['getEvent']('splash');
        
        setTimeout(() => {
            document.getElementById("newdiv5").style.display = "flex";
        }, 1000);
    }, []);


    return (
        // <><div>
        //     <a target='_blank' href='https://www.accenture.com/us-en/insights/consulting/activating-responsible-leadership '><img src={logo} alt="no se" /></a>
            
        // </div>

        //     <div id="splash">
        //         <div id="titular">
        //             <div id="logo" className="text-centered">
        //                 <img src={splashLogo} alt="logo" />

        //                 <h1 className="splashText">Leading Responsible Organisations</h1>
        //             </div>
        //         </div>

        //         <div id="info" style={{width:'786px'}}>
        //             <p className="paragraph">The climate crisis.A 'left behind' workforce.
        //             The Fourth Industrial Revolution. Covid-19. Global economic fragility.
        //             The world today faces extraodinary challenges, and further change is
        //                 inevitable.Is your organization equipped to lead responsibly through change?
        //             </p>
        //         </div>
        //         <div id="diag-button" className="text-centered">
        //             <ColorButton
        //                 className="button container"
        //                 classes={{
        //                     label: "button-text"
        //                 }}
        //                 onClick={() => { history.push('/landing'); } }
        //                 variant="contained"
        //                 color="primary">
        //                 ENTER DIAGNOSTIC
        //             </ColorButton>
        //         </div>

        //     </div></> 
       <Grid container>
        {/* <Grid container md={12} sm={12} xs={12}>
             <Grid item md={2} sm={2} xs={2} justify = "flex-start">
                 <a target='_blank' href='https://www.accenture.com/us-en/insights/consulting/activating-responsible-leadership '><img src={logo} alt="no se" style = {{
                    marginLeft:'1rem',marginTop:'1rem'
                 }} /></a>
             </Grid>
         </Grid> */}
         {/* <Grid container justify = "center">
            <Grid item className={classes.logo}>
                <img src={splashLogo} alt="logo" className = {classes.splashLogo} />
            </Grid>
         </Grid> */}
         <Grid container md={12} sm={12} xs={12} xl={12} justify = "center">
             <Grid item md={6} sm={6} xs={12} xl={6} alignItems="flex-end" style={{display: 'flex'}}>
                 <Grid md={12} sm={12} xs={12} xl={12}>
                    <h2 className={classes.splashText}>Shaping the<br></br>Sustainable<br></br>  Organization</h2>
                 </Grid>
             </Grid>
             <Grid container md={6} sm={6} xs={12} xl={6} justify = "center">
                 <Hidden only={['sm','md','lg','xl']}>
                    <Grid md={4} sm={7} style={{ marginTop: "2.5rem" }}>
                    <Button variant="contained"
                    color="primary" 
                    className = {classes.colorButton}
                    onClick={() => {   localStorage.setItem('splashcheck' ,'true')
                    history.push('/landing')}}
                    >ENTER DIAGNOSTIC</Button>
                    </Grid>
                 </Hidden>
                <Hidden only={'xs'}>
                    <Typography className = {classes.paragraph}><span style={{ fontFamily: "Graphik-Medium", fontSize: ".85rem" }}>Everyone expects more from business.</span> <br></br>
                    Leadership teams are being challenged to build sustainable organizations that deliver financial value 
                    with societal and environmental impact. To help you identify how well equipped your organization is 
                    to <i style={{ fontSize: ".85rem" }}>“create value while leading with values”</i>, we have developed the Sustainable Organizations Diagnostic.
                    </Typography>
                </Hidden>
             </Grid>
         </Grid>
         <Grid container  md={12} sm={12} xs={12}>             
             <Grid item md={6} sm={6} xs={12}>
                 <Hidden only={['sm','md','lg','xl']}>
                    <Typography className = {classes.paragraph}><span style={{ fontFamily: "Graphik-Medium", fontSize: "1rem" }}>Everyone expects more from business.</span> <br></br>
                    Leadership teams are being challenged to build sustainable organizations that deliver financial value 
                    with societal and environmental impact. To help you identify how well equipped your organization is 
                    to <i style={{ fontSize: ".85rem" }}>“create value while leading with values”</i>, we have developed the Sustainable Organizations Diagnostic.
                    </Typography>
                 </Hidden>
             <Hidden only="xs">
                <Grid container md={12} sm={12} xl={12} justify="flex-start" alignItems="center" style={{ marginTop: '2.5rem', marginBottom: '2.5rem', marginLeft: '6rem'}}>
                    <Button variant="contained"
                    color="primary" 
                    className = {classes.colorButton}
                    
                    onClick={() => {   localStorage.setItem('splashcheck' ,'true')
                    history.push('/landing')}}
                    >ENTER DIAGNOSTIC</Button>
                    </Grid>
                 </Hidden>
             </Grid>
             <Grid container md={6} sm={6} justify = "center">
                 
             <Typography className={classes.paragraph} style={{marginTop: '1rem', marginBottom: '1rem'}}>
                Developed in partnership with the World Economic Forum and powered by Arabesque S-Ray, this tool provides you with an indication of the strength of your Sustainability DNA and  
                is based on our <a className={classes.hyperlinks} style ={{ textDecoration: "none" }} target='_blank' href='https://www.accenture.com/us-en/insights/sustainability/sustainable-organization'><span className={classes.hyperlinks}>Shaping the Sustainable Organization</span><span className={classes.hyperlinks} style ={{ fontFamily: "Graphik-Regular", textDecoration: "none"}}> report.</span>​​​​​​​</a>
                {/* Developed in partnership with the World Economic Forum and powered
                by Arabesque S-Ray, this rapid diagnostic will help you to
                understand the maturity of your organization's sustainability
                infrastructure based on the <a style ={{color:"inherit", fontSize:'inherit'}} target='_blank' href='https://www.accenture.com/gb-en/insights/consulting/activating-responsible-leadership'>5 Elements of Responsible Leadership</a>
                &nbsp;and give an indication of what leaders can do to build responsible
                organizations that drive sustainable growth. */}
                </Typography>
                </Grid>
         </Grid>
    </Grid>
    
    )
}

// <Grid container >
//         <Grid container md={12} sm={12} xs={12}>
//              <Grid item md={2} sm={2} xs={2} justify = "flex-start">
//                  <a target='_blank' href='https://www.accenture.com/us-en/insights/consulting/activating-responsible-leadership '><img src={logo} alt="no se" style = {{
//                     marginLeft:'1rem',marginTop:'1rem'
//                  }} /></a>
//              </Grid>
//          </Grid>
//          <Grid container md={12} sm={12} xs={12} xl={12} justify = "center" className={classes.marginSplash}>
//              <Grid item md={6} sm={6} xs={12}> 
//              </Grid>
//              <Grid container md={6} sm={6} xs={12} justify = "center">
//                  <Grid md={4} sm={7}>
//                  <img src={splashLogo} alt="logo" className = {classes.splashLogo} />
//                  </Grid>
//                  <Grid md={12} sm={12} xs={12}>
//                  <h2 className={classes.splashText}>Leading Responsible<br></br> Organizations</h2>
//                  </Grid>
//                  <Grid container md={12} sm={12} xs={12} justify = "center">
//                  <Grid md={4} sm={7}>
//                  <Button variant="contained"
//                  color="primary" 
//                  className = {classes.colorButton}
//                  onClick={() => { history.push('/landing')}}
//                  >ENTER DIAGNOSTIC</Button>
//                  </Grid></Grid>
//              </Grid>
//          </Grid>
//          <Grid container  md={12} sm={12} xs={12}>
             
//              <Grid item md={6} sm={6} xs={12}>
//               <Typography className = {classes.paragraph}>The climate crisis. A 'left behind' workforce.
//                  The Fourth Industrial Revolution. Covid-19. Global economic fragility.
//                  The world today faces extraodinary challenges, and further change is
//                  inevitable. Is your organization equipped to lead responsibly through change?
//                  </Typography>
//              </Grid>
//          </Grid>
//     </Grid>
//     )