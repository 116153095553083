import React, { FunctionComponent } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {
	ConfirmationButtons,
	Message,
	YesButton,
	NoButton,
	Wrapper,
} from './CustomConfirmModal.style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, FormControlLabel, FormGroup, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

import captachaLogo from "../../public/img/captcha_logo.svg";

interface ConfirmationModalProps {
	onStartSurveyClicked: () => void;
    termsAccepted: boolean;
  onTermChanged: (t: boolean) => void;
  emailValidation: (t: string) => void;
  userNameChanged: (t: string,type:string) => void;
  textValidation: (t: string,type:string) => void;
  isStartSurveyDisabled:boolean;
  onCancel:() => void;
  userName:string
  lastName:string;
  country:string;
  page :string;
  message: string;
  roleName:string;
  organisationName: string;
  seniority:string;
  emailAddress:string;
  isAccepted:boolean;
  disableOrgText:boolean;
  onClicked: (t: boolean) => void;
  errorMessage:string;  
  usererrorMessage:string;
  lastNameerrorMessage:string;
  roleerrorMessage:string;
  organisationerrorMessage:string;
  countries: string[]

}
const WhiteCheckbox = withStyles({
    root: {
        color: '#ABB0B6',
        '&$checked': {
            color:  '#006F12',
        },
       
    },
    checked: {},
})(Checkbox);
const StyledMenuItem = withStyles({
    root: {
            fontFamily:"Graphik-Regular !important",
            fontSize:'0.875rem',  
        }
})(MenuItem);

export const ConfirmationModal: FunctionComponent<
	ConfirmationModalProps
> = props => {
  const classes = makeStyles(t => createStyles({
    input: {
        color: "white",
        '& input': {
            color: "#ABB0B6"
        },
        '& .MuiInputBase-root': {
            borderColor: '#ABB0B6',
        },
        '& fieldset': {
            borderColor: '#ABB0B6 !important',
            borderRadius:'0%'
        },
        '& input:hover + fieldset': {
            borderColor: '#ABB0B6 !important',
        },
        
    },
    select: {
        '& svg': {
            top: 'calc(50% - 0.75rem)',
            color:'#ABB0B6'
        }, 
        '& input': {
            color: "#000000"
        },  
        '& fieldset': {
            borderColor: '#ABB0B6 !important',
            borderRadius:'0%'
        },
        '& input:hover + fieldset': {
            borderColor: '#ABB0B6 !important',
        }
    },
    innerContainer: {
        padding: "0.375rem 0.125rem 0.375rem 0.75rem",
        fontFamily : "Graphik-Regular",
        fontColor: "#ABB0B6",
        fontStyle: "400",
        fontSize:'0.875rem',
        letterSpacing:'0.019rem',
        opacity:'0.5',
        lineHeight: 'normal'

    },
     innerContainerSelect: {
        padding: "0.375rem 0.125rem 0.375rem 0.75rem",
        fontFamily : "Graphik-Regular",
        fontColor: "#ABB0B6",
        fontStyle: "400",
        fontSize:'0.875rem',
        letterSpacing:'0.019rem',
        opacity:'100%',
        lineHeight: 'normal'
    },
    inputInnerPadding: {
        padding: "0.375rem 0.125rem 0.375rem 0.75rem",
        fontFamily : "Graphik-Regular !important",
        color: "#000000 !important",
        fontStyle: "400 !important",
        fontSize:'0.875rem !important',
        letterSpacing:'0.019rem !important'
        
    },
    inputContainerMargin: {
        marginTop: `25px`
    },
    inputSpacing: {
        paddingRight: `${t.spacing(4)}px`
    },
    dropdown: {
        margin:'10px 0',
        maxWidth:'none',
        whiteSpace:'pre-wrap',
        overflowWrap:'break-word',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(217,76,181,0.54)',
        },
        [t.breakpoints.down('sm')]: {
            // margin:'auto 0px 10px 10px',
            // maxWidth:'fit-content',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }    
    }
}))()
	return (
        <React.Fragment>
             {props.page === 'Results' ?  
                         
              <Grid container className="popUp">
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
                <Grid item xs={12}>
                    <label className="reportSideHeadingText">First Name*</label>
                </Grid>
                <Grid item xs={12}>
                <TextField
                 className={`${classes.input}`}
                    placeholder="Enter First Name"
                    variant="outlined"
                    fullWidth
                    inputProps={
                        {
                          className: `${classes.inputInnerPadding} textFieldFont`,
                            maxLength: 50
                        }
                    }
                    value={props.userName}
                    helperText={props.usererrorMessage }
                    onChange={({target: {value}}) => props.userNameChanged(value ,'username')}
                    onBlur={({target: {value}}) =>props.textValidation(value,'username')}
                />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
                <Grid item xs={12}>
                    <label className="reportSideHeadingText">Last Name*</label>
                </Grid>
                <Grid item xs={12}>
                <TextField
                 className={`${classes.input}`}
                    placeholder="Enter Last Name"
                    variant="outlined"
                    fullWidth
                    inputProps={
                        {
                          className: `${classes.inputInnerPadding} textFieldFont`,
                            maxLength: 50
                        }
                    }
                    value={props.lastName}
                    helperText={props.lastNameerrorMessage }
                    onChange={({target: {value}}) => props.userNameChanged(value ,'lastname')}
                    onBlur={({target: {value}}) =>props.textValidation(value,'lastname')}
                />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
                <Grid item xs={12}>
                    <label className="reportSideHeadingText">Job Title*</label>
                </Grid>
                <Grid item xs={12}>
                <TextField
                 className={`${classes.input}`}
                    placeholder="Enter Job Title"
                    variant="outlined"
                    fullWidth
                    inputProps={
                        {
                          className: `${classes.inputInnerPadding} textFieldFont`,
                            maxLength: 50
                        }
                    }
                    value={props.roleName}
                    helperText={props.roleerrorMessage }
                    onChange={({target: {value}}) => props.userNameChanged(value,'rolename')}
                    onBlur={({target: {value}}) =>props.textValidation(value,'rolename')}
                />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
                <Grid item xs={12}>
                    <label className="reportSideHeadingText">Country/Location*</label>
                </Grid>
                <Grid item xs={12} >
                <Select
                    className={`${classes.select} select`}
                    style={{
                        borderRadius:'0%',
                    }}
                    fullWidth
                    classes={{
                        select: (props.country === '-1' ? classes.innerContainer : classes.innerContainerSelect)
                    }}
                    variant="outlined"
                    value={props.country}
                    onChange={({target: {value}}) => props.userNameChanged(value as string ,'country')}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        MenuListProps: {
                            style: {
                                maxHeight: "12.375rem",
                                overflowY: "auto",
                                borderRadius:'0%',
                                // whiteSpace: "normal"
                            }
                        }                       
                    }}
                >
                    <StyledMenuItem style={{display:"none"}} value={-1}> Select your country/location</StyledMenuItem> 
                    {
                    props.countries.map((v,i) => <StyledMenuItem className={classes.dropdown} value={v}>
                       {v}
                        </StyledMenuItem>)
                    }
                </Select>  
                </Grid>
                
            </Grid>
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
                <Grid item xs={12}>
                    <label className="reportSideHeadingText">Organization Name*</label>
                </Grid>
                <Grid item xs={12} style={{paddingTop:'10px'}}>
               { (props.organisationName && props.organisationName != '' && props.disableOrgText )
                ?     <TextField
                 className={`${classes.input}`}
                   // placeholder="Enter Name"
                    variant="outlined" disabled
                    fullWidth
                    inputProps={
                        {
                            className: `${classes.inputInnerPadding} textFieldFont`,
                            maxLength: 50
                        }
                    }
                    value={props.organisationName}
                 
                />
                :
                <TextField
                className={`${classes.input}`}
                   placeholder="Enter Organization Name"
                   variant="outlined"
                   fullWidth
                   inputProps={
                       {
                         className: `${classes.inputInnerPadding} textFieldFont`,
                           maxLength: 50
                       }
                   }
                   value={props.organisationName}
                   helperText={props.organisationerrorMessage }
                   onChange={({target: {value}}) => props.userNameChanged(value,'organisation')}
                   onBlur={({target: {value}}) =>props.textValidation(value,'organisation')}
               />
}
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
                <Grid item xs={12}>
                    <label className="reportSideHeadingText">E-mail Address*</label>
                </Grid>
                <Grid item xs={12} > 
                <TextField
                 className={`${classes.input}`}
                    placeholder="Enter E-mail"
                    variant="outlined"
                    fullWidth
                    inputProps={
                        {
                          className: `${classes.inputInnerPadding} textFieldFont`,
                            maxLength: 50
                        }
                    }
                   
                    helperText={props.errorMessage }
                    value={props.emailAddress}
                    onChange={({target: {value}}) => props.userNameChanged(value,'email')}
                    onBlur={({target: {value}}) =>props.emailValidation(value)}
                />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}  md={12} className="paddingReport">
            <FormGroup style={{flexDirection: 'row'}}>
                <FormControlLabel 
                    control={
                    <WhiteCheckbox
                        checkedIcon={<CheckBoxOutlinedIcon/>}
                        checked={props.termsAccepted}
                        onChange={({target:{checked}}) => props.onTermChanged(checked)}
                        name="terms"
                    />
                    }
                    label={<label id="termsResult" >
                      I agree that Accenture processes my personal data in accordance with Accenture’s privacy policy for providing the Report and potential individual follow-up purposes. You have the right to withdraw your consent at any time by completing our <a style ={{color:"#393A3E", fontSize: '0.625rem'}} target='_blank' href='https://www.accenture.com/us-en/form-manage-data-preferences'>Data Rights and Preferences</a> form.*
                      
                        {/* I hereby agree that Accenture processes my personal data in accordance with Accenture’s <a style ={{color:"#393A3E", fontSize: '0.625rem'}} target='_blank' href='https://www.accenture.com/us-en/about/privacy-policy'>privacy policy</a>. You can withdraw your consent at any time by sending an email to  <a  target='_blank' style ={{color:"#393A3E", fontSize: '0.625rem'}} href="mailto:dataprivacyofficer@accenture.com">dataprivacyofficer@accenture.com </a>mentioning this survey.  */}
                        </label>
                }
                          />
            </FormGroup>
            </Grid>
            <Grid container item xs={12} sm={12}  md={12}>
            <FormGroup style={{flexDirection: 'row'}}>
                <FormControlLabel 
                    control={
                    <WhiteCheckbox
                        checkedIcon={<CheckBoxOutlinedIcon/>}
                        checked={props.isAccepted}
                        onChange={({target:{checked}}) => props.onClicked(checked)}
                        name="terms"
                    />
                    }
                    label={<label id="termsResult" >

                    I agree to give Accenture permission to send me marketing-related emails.​We will only contact you about relevant news and information that we think would be of interest to you as it relates to our events, thought leadership and other marketing initiatives. You have the right to withdraw your consent at any time by completing our <a style ={{color:"#393A3E", fontSize: '0.625rem'}} target='_blank' href='https://www.accenture.com/us-en/form-manage-data-preferences'>Data Rights and Preferences</a> form.
                     
                   {/* I agree that Accenture can send me interesting email updates (e.g. reports, insights, trends, service updates) and invite me to events. */}
                         </label>}
                />
            </FormGroup>
            </Grid>
            <Grid container item xs={12}  style = {{marginTop: '0.938rem'}} >
            <Grid  item xs={6} md={6} style={{textAlign: "center"}}>
            <Button
                variant="contained" 
                style={{border: '1px solid #979797',background: 'white',width:'6.25rem',height:'1.875rem',borderRadius: '0%'}}
                classes={{
                    label: "buttonResultText"
                }}
                onClick={props.onCancel}
                >Cancel</Button>
                </Grid>
                <Grid  item xs={6} md={6} style={{textAlign: "center"}}>
                {props.isStartSurveyDisabled ? 
                <Button
                variant="contained" 
                style={{border: '1px solid #979797',background: '#E6E6DC',width:'8.125rem',height:'1.875rem',borderRadius: '0%'}}
                classes={{
                    label: "buttonSendResultText"
                }}
                disabled={props.isStartSurveyDisabled} 
                >Download Report</Button>
                :
                                <Button
                                id="createpdf"
                                onClick={props.onStartSurveyClicked}
                variant="contained" 
                style={{border: '1px solid #979797',background: '#A100FF',width:'8.125rem',height:'1.875rem',borderRadius: '0%'}}
                classes={{
                    label: "buttonResultTextActive"
                }}
                
                >Download Report</Button>
}

                </Grid>
            </Grid>
            
        
        </Grid>: 
        (props.page === "Success" ? 
        <><div className="confirmPopuptext">{props.message}</div>
                     <div style={{textAlign :'center',paddingTop:'0.625rem'}}>  <Button
                            id="createpdf"
                            onClick={props.onCancel}
                            variant="contained"
                            style={{ border: '1px solid #979797', background: '#A100FF', width: '3.375rem', height: '2.5rem', borderRadius: '0%' }}
                            classes={{
                                label: "buttonResultTextActive"
                            }}

                        >OK</Button></div></>  :
      <Message>
 
          <div style={{ padding: '0.625rem', display: 'flex', alignItems: 'center'}}>
                    <WhiteCheckbox
                        checkedIcon={<CheckBoxOutlinedIcon/>}
                       // checked={props.isTermAccepted}
                        onChange={({target:{checked}}) => props.onStartSurveyClicked()}
                         name="terms"
                    />
                    
                  
                        <span id="capatcha-font">{props.message}</span> 
                         <img src={captachaLogo} alt="logo" style={{ width: '2.938rem'}} />
                         </div>

            
            </Message>)
}
          
          
      {/* <ConfirmationButtons>
        <YesButton onClick={props.onConfirm}>Yes</YesButton>
        <NoButton onClick={props.onCancel}>No</NoButton>
      </ConfirmationButtons> */}
		</React.Fragment>
	);
};