import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
  import logo from "../public/img/accenture_logo_new.svg";
  import{Grid, Button} from '@material-ui/core';
  import Hidden from '@material-ui/core/Hidden';
  import {withStyles,Theme, createStyles,makeStyles  } from '@material-ui/core/styles';
import analyticsOfPage from "../Components/AdobeAnalytics";

  const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    marginSplash:{
        marginLeft: '5rem',        
        marginBottom: '7rem',
        [theme.breakpoints.down('md')]: {
          marginLeft: '5rem',

         },
         [theme.breakpoints.down('xs')]: {
          marginLeft: '2rem',
          marginBottom:'7rem'

         },
         [theme.breakpoints.up('xl')]: {
          marginLeft: '5rem',

         }
    },
    resultsReport:{
      fontFamily: 'Graphik-Bold',
      fontSize: '2.188rem',
      color: '#FFFFFF',
      letterSpacing:' 0.42px',
      fontWeight: '700',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',

       },
    },
    latestReport:{
      fontFamily: ' Graphik-Medium',
      fontSize: '1.111rem',
      color: '#00FFFA',
      letterSpacing:' 0.31px',
      fontWeight: '500',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.111rem',

       },
    },
    listText:{
      fontFamily: ' Graphik-Regular',
      fontSize: '1.25rem',
      color: '#FFFFFF',
      letterSpacing:' 0.24px',
      lineHeight:'2.5rem',
      fontWeight: '400',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        lineHeight:'1.2rem'

       },
    },
    contactlistText:{
      fontFamily: ' Graphik-Regular',
      fontSize: '1rem',
      color: '#FFFFFF',
      letterSpacing:' 0.012rem',
      lineHeight:'1.375rem',
      fontWeight: '400',
    },
    listIcon:{
    // marginLeft:'-1.3rem',
      fontSize: '2rem',
      color: '#FFFFFF',
      letterSpacing:' 0.24px',
      fontWeight: '400',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        lineHeight:'1.2rem',
        marginLeft:'-1.3rem',
        marginBlockEnd: '1em'


      
    },
    [theme.breakpoints.down('sm')]: {
    
    
      marginLeft:'-1.3rem'


    
  }
  },
  hyperLinkText:{
    color: '#FFFFFF',
  fontSize: '1rem',
  [theme.breakpoints.down('xs')]: {
    fontSize: '1rem',
   


  
}
  },
  colorButton: {
    margin: '0.25rem 1rem 0.25rem 0',
    backgroundColor: "#A100FF",
    '&:hover': {
      backgroundColor: '#A100FF',
    },
    fontFamily: 'Graphik-Bold',
    fontSize: '16px',
    color: '#FFFFFF',
  }
  })
);



export default function EndPage() {

  useEffect(() => {  
    // analyticsOfPage("endPage");
    window['getDataModel']('endPage')
    window['getEvent']("endPage");
  }, []);

 
  const classes = useStyles();

    let history = useHistory();
    if( localStorage.getItem('splashcheck') != 'true') 
    {
        history.push('/')
    }
    else if( localStorage.getItem('resultspagecheck') != 'true') 
    {
        history.push('/landing')
    }
  

  

    return (
      
      <>
         <Grid container md={12} sm={12} xs={11} xl={12}  className={classes.marginSplash}>
<Grid item md={12} sm={12} xs={11} xl={12}>

{/* <Hidden only={'xs'}> */}
<p className={classes.resultsReport}>Thank you for completing the<br/>Sustainable Organizations Diagnostic</p>
{/* </Hidden> */}
{/* <Hidden only={['sm','md','lg','xl']}>
<p className={classes.resultsReport}>Thank you for completing the  
Building Sustainable Organizations Diagnostic</p>
  </Hidden> */}
</Grid>
<Grid item md={10} sm={10} xs={11} xl={10}>
  <p className={classes.latestReport} style={{color: '#FFFFFF', marginBlockStart: '0'}}>
    Your diagnostic report has been downloaded. We invite you to restart the diagnostic 
    from another perspective - either personal or organizational - to compare how your 
    individual values may differ from your organization's.
  </p>
</Grid>
<Grid item md={11} sm={10} xs={10} xl={12}>
<span className={classes.latestReport}>Read our latest thinking</span>
<ul>
  <li className={classes.listIcon}>
    <span className={classes.listText}>
      <a className={classes.hyperLinkText} target='_blank' href="https://www.accenture.com/us-en/insights/sustainability/sustainable-organization">Explore more about how leaders are shaping sustainable organizations </a>
    </span>
  </li>
  <li className={classes.listIcon}>
    <span className={classes.listText}>
      <a className={classes.hyperLinkText} target='_blank' href="https://www.accenture.com/us-en/insights/consulting/responsible-leadership">Read about how businesses and organizations are redefining responsible leadership​​​​​​​</a>
    </span>
  </li>
  <li className={classes.listIcon}>
    <span className={classes.listText} >
      <a className={classes.hyperLinkText} target='_blank' href="https://www.accenture.com/us-en/insights/consulting/activating-responsible-leadership">See how responsible leaders are creating value while leading with values</a>
    </span>
  </li>  
</ul>  
<span className={classes.latestReport}>Learn how we can help</span>
<ul>
  <li className={classes.listIcon}>
    <span className={classes.listText} >
      <a className={classes.hyperLinkText} target='_blank' href="https://www.accenture.com/us-en/services/talent-organization-human-potential-index">Learn more about our Change Management services</a>
    </span>
  </li>
  <li className={classes.listIcon}>
    <span className={classes.listText}>
      <a className={classes.hyperLinkText} target='_blank' href="https://www.accenture.com/us-en/services/sustainability-index">Learn more about our Sustainability services</a>
    </span>
  </li>
</ul>  
{/* <Hidden only={['sm','md','lg','xl']} >
<p className={classes.contactlistText} style={{marginTop:'3.5rem'}}>Please contact <a style={{ color: "#FFFFFF",fontSize:'0.8rem' }}  target='_blank' href="mailto:sustainableorg@accenture.com">sustainableorg@accenture.com </a> if you have any questions </p>

</Hidden> */}

<Button 
variant="contained" 
                      color="primary"                      
                      className={classes.colorButton}
                      onClick={() => { localStorage.setItem('onclickrestartDiagnostic','true')
                                        {history.push('/landing')} } }
                    >
                      RESTART DIAGNOSTIC
                  </Button> 

{/* <Hidden only={'xs'} > */}
<p className={classes.contactlistText}>Please contact <a style={{ color: "#FFFFFF", textDecoration: 'underline' }} target='_blank' href="mailto:sustainableorg@accenture.com">sustainableorg@accenture.com</a> if you have any questions</p>

{/* </Hidden> */}

</Grid>
</Grid>

    

</>
    )
}
