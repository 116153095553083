import { createStyles, makeStyles, StyledComponentProps, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

export interface ICommonScreenProps<TScreenProps, TFooterProps> {
    screenProps: TScreenProps,
    footerProps: TFooterProps
}

const footerHeight = "100px"
const styles = ((t: Theme) => createStyles({
    screenStyle: {
        // display: "grid",
        
      /**   margin: "0 90px",**/
        height: "auto",

        // gridTemplateRows: `calc(100% - ${footerHeight}) ${footerHeight}`,

        [t.breakpoints.down("sm")]: {
            margin: "0"
        }
    },
    content: {
        height: "100%",
      /**  overflowY: "auto" ,**/
        marginLeft:'3.125rem'
    },
    footer: {
        paddingTop: "1.25rem",
        marginLeft:'3.125rem'
    }
}))

/**
 * HOC that serves as base structure to reduce new screen's workout
 * @param ScreenView Component that represents screen's content
 * @param FooterView Component that represents screen's footer
 * @returns Component that accepts as props: 
 *  - screenProps, which contains props for ScreenView
 *  - footerProps, which contains props for FooterView
 */
export function withCommonScreen<TScreenProps, TFooterProps>(
    ScreenView: React.ComponentType<TScreenProps>, 
    FooterView: React.ComponentType<TFooterProps>
){
    return withStyles(styles)(class WithCommonScreenKlass 
        extends React.Component<
            ICommonScreenProps<TScreenProps, TFooterProps> & WithStyles<typeof styles>
        >
    {
        render(){
            const classes = this.props.classes
            return <div className={classes.screenStyle}>
                <div className={classes.content}>
                    <ScreenView {...this.props.screenProps}/>
                </div>
                <div className={classes.footer}>
                    <FooterView {...this.props.footerProps}/>
                </div>
            </div>
        }
    })
}