import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 700;
	width: inherit;
	outline: 0;
`;

export const Backdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 500;
`;

export const StyledModal = styled.div`

  background: #FFFFFF;
box-shadow: 0 0 15px 0 rgba(34,34,34,0.10);
width: 23.5rem;
height: 9rem;
@media(max-width: 425px) {
	width: 80vw;
	height: auto;
}
`;
export const StyledNewModal = styled.div`

background: #FFFFFF;
box-shadow: 0 0 15px 0 rgb(34 34 34 / 10%);
@media(min-width: 768px) {
width: 35rem;
}
width: 15.25rem;
/*height: 29.563rem;*/
padding: 1.875rem;
`;

export const Header = styled.div`
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
	padding: 0.3rem;
`;

export const HeaderText = styled.div`
font-family: Graphik-Regular;
font-size: 0.875rem;
color: #393A3E;
letter-spacing: 0;
line-height: 1.75rem;
font-weight: 400;
padding: 0.625rem;
width: 100%;
`;

export const CloseButton = styled.button`
	font-size: 0.8rem;
	border: none;
	border-radius: 3px;
	margin-left: 0.5rem;
	background: none;
	:hover {
		cursor: pointer;
	}
`;

export const Content = styled.div`
	padding: 0 0.938rem 0.625rem 0.938rem;
	max-height: 30rem;
	overflow-x: hidden;
	overflow-y: auto;
`;

