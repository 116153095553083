
import { Box, Button, createStyles, Grid, Hidden,makeStyles, LinearProgress, LinearProgressProps, Typography, withStyles } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import IAnsweredQuestion from "../../Common/IAnsweredQuestion";
import QuestionContainer from "../../Components/QuestionContainer";

const QuestionProgressBar = function(props: LinearProgressProps & { value: number }){


    const StyledLinearProgress = withStyles(t => createStyles({
        root: {
            height: "1.85676vh",
            borderRadius: "7px",
            backgroundColor: "#794089"
        },
        bar:{
            backgroundColor:  props?.value == 0 ?"#794089" :  '#3EDD7B',
            borderRadius: '1px',
            opacity: 0.8,
            transform:'rotate(-270deg)'

        }
    }))(LinearProgress)

    return <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
            <StyledLinearProgress
                variant="determinate" 
                {...props}
            />
        </Box>
        <Box minWidth={35}>
            <p className="bodyText" style={{marginBlockEnd: '1.2rem'}}>{`${Math.round(props.value)}%`}</p>
        </Box>
    </Box>
}



export interface ISurveyPageContentProps {
    onSubmitClick(): void;
    isSubmitDisabled: boolean;
    questions: IAnsweredQuestion[]
    onQuestionChanged: (newQuestion: IAnsweredQuestion, idx: number) => void
}



export default function SurveyPageContent(props: ISurveyPageContentProps){
  /*   useEffect(() => {
        if( document.getElementById('newdiv')){
            document.getElementById('newdiv').scrollTop = 0;
        }
    }); */
    const progressValue = props.questions.filter(v => v.scale !== undefined).length / props.questions.length * 100

    const classes = makeStyles(t => createStyles({
        xsCenterAlign: {
          [t.breakpoints.down('xs')]:{
            marginLeft:'-10px'
        }
        },
        title: {
            paddingTop: '1rem',
            fontFamily: "Graphik-Bold",
            fontSize: "1.463rem",
            color: "#ffffff",
            letterSpacing: "0.022rem",
            margin: "0",
            [t.breakpoints.down('xs')]:{
                fontSize: "1.25rem",
                margin: '5px 5px 0 0'
            }
        },
        bodyText: {
            fontFamily: "Graphik-Medium",
            fontSize: "1rem",
            color: "#ffffff",
            letterSpacing: "0.012rem",
            margin: '0',
            [t.breakpoints.down('xs')]:{
                fontSize: "0.9rem",
                margin: '5px 20px 0 0'
            }
        },
    
      }))()
    return (
    <>
        <div id = 'survey' className = {classes.xsCenterAlign}>
        <Hidden only={['sm','xs']}>
        
            <h1 className={classes.title}>Sustainable Organizations - Diagnostic Questions</h1>
     
       
            <p className={classes.bodyText}>To what extent do you agree that you/your organization (where 1 = completely disagree and 7 = completely agree)?</p>
            </Hidden>
            <Hidden only={['md','lg','xl']}>
            <h1 className={classes.title}>Sustainable Organizations - Diagnostic Questions</h1>
     
       
     <p className={classes.bodyText} style={{paddingTop:'1rem'}}>To what extent do you agree that you/your organization (where 1 = completely disagree and 7 = completely agree)?</p>
     </Hidden>
        
            {props.questions && props.questions.length >1  ?   <Grid container justify = 'center'>
            <Grid item sm={10} xs={10} md={8}>
                <QuestionProgressBar value={progressValue} />
            </Grid>
        </Grid> : "" }
       
       
            <QuestionContainer questions={props.questions} 
                onQuestionChanged={props.onQuestionChanged}
                isSubmitDisabled= {props.isSubmitDisabled}
                onSubmitClick={props.onSubmitClick}/>
       
               </div>
    </>
    
        // <Grid container  >
        //     <Grid container style = {{position:"fixed", zIndex:"2"}}>
        //         <Grid container md={12} sm={12} xs={12} style = {{marginTop:"1rem"}}>
        //         <Typography   variant="h4"  gutterBottom style = {{color: "white"}}>
        //         Leading Responsible Organisations - Questions
        //         </Typography>
        //     </Grid>
        //     <Grid container md={12} sm={12} xs={12}  >
        //         <Typography  variant="h6"  gutterBottom style = {{color: "white"}}>
        //         To what extent do you agree with the following statements on a 1 - 7  scale, where 1 = completely disagree and 7 = completely agree.
        //         </Typography>
        //     </Grid>
        //  </Grid>
        //     <Grid container style = {{marginTop:'6rem'}}>
        //     <QuestionContainer questions={props.questions} 
        //         onQuestionChanged={props.onQuestionChanged}
        //         isSubmitDisabled= {props.isSubmitDisabled}
        //         onSubmitClick={props.onSubmitClick}/>
        //     </Grid>
        // </Grid>
    
    )
}