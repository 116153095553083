import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Modal } from '../../Components/Modal/Modal';
import { ConfirmationModal } from '../../Components/CustomConfirmModal/CustomConfirmModal';
import { useHistory } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { useModal } from './useModal';
import grey from "@material-ui/core/colors/green";
import { FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';


export interface ILandingPageFooterProps {
    isTermAccepted: boolean
    onTermChanged: (isSelected: boolean) => void
    isStartSurveyDisabled: boolean
    onStartSurveyClicked: () => void
}

//Checkbox
const WhiteCheckbox = withStyles({
    root: {
        color: grey[50],
        '&$checked': {
            color: grey[50],
        },
        transform: "scale(1.55)"
    },
    checked: {},
})(Checkbox);

export default function LandingPageFooter(props: ILandingPageFooterProps) {
    const { isShown, toggle } = useModal();
  
   
    
    return (
        <></>
      /*   <React.Fragment>
        <Grid container style={{textAlign: "left"}}>
            <Grid item xs={12} md={5} >
            <FormGroup >
                <FormControlLabel style={{margin: "unset" ,width:'450px'}}
                    control={
                    <WhiteCheckbox
                        checkedIcon={<CheckBoxOutlinedIcon/>}
                        checked={props.isTermAccepted}
                        onChange={({target:{checked}}) => props.onTermChanged(checked)}
                        name="terms"
                    />
                    }
                    label={<label id="terms-font" >By clicking 'Start Survey', I agree to the <a style ={{color:"#FFFFFF"}} target='_blank' href='https://www.accenture.com/us-en/support/terms-of-use'> Terms of Use</a> and  <a style ={{color:"#FFFFFF"}} target='_blank' href='https://www.accenture.com/us-en/about/privacy-policy'>Privacy Policy</a></label>}
                />
            </FormGroup>
            </Grid>
          
              
           
                   
           <Grid  item xs={12} md={4} justify="center">
            <Button
                variant="contained" 
                className="button container bg-white"
                classes={{
                    label: "button-text"
                }}
                disabled={props.isStartSurveyDisabled} 
                onClick={toggle}>START SURVEY</Button>
            </Grid>
            
            </Grid>
            
    
         <Modal
          isShown={isShown}
          hide={toggle}
          headerText='Please check the box below to proceed.'
          modalContent={
            <ConfirmationModal 
            onStartSurveyClicked = {props.onStartSurveyClicked}
              message='I’m not a robot'
            />
          }
        />
        </React.Fragment>
   */
  )
}
