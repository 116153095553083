import { Button, createStyles, Radio, Grid, Hidden, makeStyles, Tooltip, Tabs, Tab } from '@material-ui/core'
import React, { useState } from 'react'
import { Polar, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from "lodash";
import { useModal } from './useModal';
import { Modal } from '../../Components/Modal/Modal';
import { ConfirmationModal } from '../../Components/CustomConfirmModal/CustomConfirmModal';
import Result from '../../Common/DTO/Results';
import BenchmarkScore from '../../Common/DTO/BenchmarkScore';
import Chart from 'chart.js';
import { useHistory } from 'react-router';
import LoadingOverlay from 'react-loading-overlay';

export interface IResultPageContentProps {
	roleName: string;
	organisationName: string;
	seniority: string;
	emailAddress: string;
	onStartSurveyClicked: () => void;
	onClickContactInfo: (t: boolean) => void
	showContactPopup: boolean,
	selectOption: string,
	organisationBenchMarkScore: number
	sizeBenchMarkScore: number,
	industryBenchMarkScore: number,
	geographyBenchMarkScore: number,
	benchmarkResults: BenchmarkScore,
	results: Result,
	landingdetails: {
		industryValue: string,
		geographyValue: string
		sizeValue: string,
		seniorityValue: string,
		organisationName: string
	},
	onClickLandingInfo: (t: string) => void,
	lroScoreValues: [],
	organisationScoreValues: [],
	sizeScoreValues: [],
	industryScoreValues: [],
	geographyScoreValues: [],
	termsAccepted: boolean;
	onTermChanged: (t: boolean) => void;
	userNameChanged: (t: string, type: string) => void;
	textValidation: (t: string, type: string) => void;
	isStartSurveyDisabled: boolean;
	userName: string,
	lastName: string,
	country: string,
	isAccepted: boolean;
	onClicked: (t: boolean) => void;
	errorMessage: string;
	usererrorMessage: string,
	lastNameerrorMessage: string,
	roleerrorMessage: string,
	organisationerrorMessage: string,
	emailValidation: (t: string) => void;
	showPopup: boolean
	onCancel: () => void;
	onSend: () => void;
	showConfirmPopup: boolean;
	loadingIcon: boolean,
	confirmMessage: string,
	visible: boolean
}

export default function ResultsContent(props: IResultPageContentProps) {
	const { isShown, toggle } = useModal();
	const [radioSelected, setRadioSelected] = useState("average");
	const [tabSelected, setSelectedTab] = useState(0);

	// Polar Chart Global Declarations
	Chart.plugins.register(ChartDataLabels);
	Chart.defaults.global.defaultFontColor = "#FFFFFF";
	Chart.defaults.global.defaultFontFamily = "Graphik-Regular";
	Chart.defaults.global.defaultFontSize = window.innerWidth >= 1280 && window.innerHeight >= 720 ? (((2.25 * window.innerHeight) / 100) / 1) : 14;
	Chart.defaults.global.legend.labels.padding = window.innerWidth >= 1280 && window.innerHeight >= 720 ? (((2.25 * window.innerHeight) / 100) / 1.125) : 18;

	let history = useHistory();
	const countries = ["Afghanistan", "Aland Islands", "Albania", "Algeria", "American Somoa", "Andorra", "Angola", "Anguilla", "Antarctica",
		"Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
		"Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bonaire, Sint Eustatius and Saba", "Bosnia and Herzegovina",
		"Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory", "British Virgin Islands", "Brunei", "Bulgaria", "Burkina Faso",
		"Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China",
		"Christmas Island", "Coconut Islands", "Colombia", "Comoros", "Congo (Dem Republic)", "Congo (Republic)", "Cook Islands", "Costa Rica",
		"Croatia", "Cuba", "Cura&#231;ao", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Dutch Antilles",
		"East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands",
		"Federated Micronesia", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Territories", "Gabon", "Gambia",
		"Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea",
		"Guinea-Bissau", "Guyana", "Haiti", "Heard Island and McDonald Islands", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
		"Iran, Islamic Republic of", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jersey", "Jordan",
		"Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
		"Lithuania", "Luxembourg", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique",
		"Mauritania", "Mauritius", "Mayotte", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar",
		"Namibia", "Nauru", "Nepal", "Netherlands", "New Caledonia", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island",
		"North Korea", "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau", "Palestinian Territory, Occupied", "Panama",
		"Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn", "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia",
		"Rwanda", "Saint Barthelemy", "Saint Helena, Ascension and Tristan da Cunha", "Saint Kitts and Nevis", "Saint Martin (French part)",
		"Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
		"Serbia", "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten (Dutch part)", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
		"South Africa", "South Georgia and the South Sandwich Islands", "South Korea", "South Sudan", "Spain", "Sri Lanka", "St. Lucia", "Sudan",
		"Suriname", "Svalbard and Jan Mayen", "Swaziland", "Sweden", "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste",
		"Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands", "Tuvalu", "Uganda",
		"Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay", "US Virgin Islands",
		"Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Wallis and Futuna", "Western Sahara", "Western Samoa", "Yemen", "Zambia",
		"Zimbabwe"]

	const classes = makeStyles(t => createStyles({
		xsCenterAlign: {
			[t.breakpoints.down('xs')]: {
				marginLeft: '-0.75rem',
				overflowX: 'hidden'
			}
		},
		colorButton: {
			margin: '0.25rem 1rem 0.25rem 0',
			backgroundColor: "#A100FF",
			'&:hover': {
				backgroundColor: '#A100FF',
			},
			fontFamily: 'Graphik-Bold',
			fontSize: '16px',
			color: '#FFFFFF',
			// [t.breakpoints.down('xs')]: {
			// 	margin: '0.25rem 0.5rem',
			// }
		},
		radioButton: {
			color: "#FFFFFF !important"
		},
		xsContactInfoMargin: {
			[t.breakpoints.down('xs')]: {
				marginTop: '10px',
				marginBottom: '10px'
			}
		},
		contactText: {
			fontFamily: 'Graphik-Medium',
			fontSize: "0.813rem",
			color: '#ffffff',
			letterSpacing: '0.17px',
			alignItems: 'center',
			fontWeight: 500,
			padding: '.5rem 1rem',
			justifyContent: 'center',
			[t.breakpoints.down('xs')]: {
				justifyContent: 'left',
				padding: '3px',
			}
		},
		paddingLeft: {
			padding: "0 3rem",
			textAlign: "center",
			[t.breakpoints.down('xs')]: {
				padding: "0",
				textAlign: "left",
				marginBottom: "1rem"
			}
		},
		polarGraph: {
			marginTop: "2rem",
			width: "99%",
			height: "33vw",
			[t.breakpoints.down('xs')]: {
				// height: "75vw",
				// width: "91.667%"
				height: "80vw",
				width: "100%",
				marginLeft: "-0.75rem"
			}
		},
		legend: {
			fontFamily: "Graphik-Regular",
			fontSize: "0.778rem",
			color: "#FFFFFF",
			fontWeight: 400,
			margin: ".5rem",
		},
		legendLabel: {
			display: "inline-flex",
			alignItems: "center",
			alignContent: "center",
			justifyContent: "center",
			flexWrap: "nowrap"
		},
		square: {
			width: "0.9375rem",
			height: "0.9375rem",
		},
		pentagon: {
			marginTop: "5px",
			position: "relative",
			width: "9px",
			boxSizing: "content-box",
			borderWidth: "8px 3px 0",
			borderStyle: "solid",
			"&::before": {
				content: '""',
				position: "absolute",
				height: "0",
				width: "0",
				top: "-14px",
				left: "-3px",
				borderWidth: "0 7.5px 6px",
				borderStyle: "solid",
				borderColor: "inherit"
			}
		},
		barGraphBox: {
			padding: ".875rem",
			margin: ".875rem",
			textAlign: "center",
			[t.breakpoints.down('xs')]: {
				margin: '0',
			}
		},
		barChartContainer: {
			width: "100%",
			height: "35vw",
			marginTop: "1rem",
			[t.breakpoints.down('xs')]: {
				height: "80vw",
				marginLeft: "-1rem",
				marginBottom: "1rem"
			}
		},
		textFont: {
			fontFamily: "Graphik-Medium",
			fontSize: "0.938rem",
			color: "#FFF",
			width: "100%",
		},
		tabsContainer: {
			margin: '.25rem .875rem 0',
			[t.breakpoints.down('xs')]: {
				margin: '0',
			}
		},
		tabRoot: {
			flexGrow: 2
		},
		tagMessageContainer: {
			margin: '.875rem',
			[t.breakpoints.down('xs')]: {
				margin: '0',
			}
		},
		indicator: {
			backgroundColor: '#A100FF'
		},
		customStyleOnTab: {
			fontFamily: 'Graphik-Regular',
			fontSize: '.813rem',
			color: '#ffffff',
			textTransform: 'none',
			fontWeight: 400,
		},
	}))();

	function textChange(text, page) {
		if (text) {
			let customItems = text;
			customItems = customItems.split("\n");
			if (customItems.length > 0) {
				customItems.forEach((data, i) => {
					var trimText = data.trim();
					var bulleticon = '\u2022';
					var appendedtext = `${bulleticon} ${trimText}`
					customItems[i] = appendedtext;
				});
				customItems = customItems.join("<br>");

				// The reason for assigning the string using innerHTML instead of returning the string is,
				// it contains <br> tags which are rendered as text along with the string, using innerHTML renderes it as break.
				if (page === 'strength') {
					const strengthtext = document.getElementById("strengthtext");
					strengthtext!? strengthtext.innerHTML = customItems : "";
				}
				else if (page === 'areas') {
					const developtext = document.getElementById("developtext");
					developtext!? developtext.innerHTML = customItems : "";	
				}	
			}
		}
		return "";
	}

	function buttonClick(type: "chart" | "benchmarkscore") {
		const { selectOption } = props;
		const benchMarkScore = localStorage.getItem("benchMarkscore");
		const topQuartileScores = localStorage.getItem("topQuartileScore");

		if ((benchMarkScore! && benchMarkScore != "{}") && (topQuartileScores! && topQuartileScores != "{}")) {
			let scores = radioSelected === "average" ? benchMarkScore! : topQuartileScores!;
			const { allOrganizations, size, industry, geography } = JSON.parse(scores);

			if (type === 'chart') {
				const organisationScoreValues = _.map(allOrganizations, 'elementScore');
				const sizeScoreValues = _.map(size, 'elementScore');
				const industryScoreValues = _.map(industry, 'elementScore');
				const geographyScoreValues = _.map(geography, 'elementScore');

				if (organisationScoreValues && (selectOption === "AllOrg" || selectOption == "")) {
					return organisationScoreValues;
				}
				else if (sizeScoreValues && selectOption === "Size") {
					return sizeScoreValues;
				}
				else if (industryScoreValues && selectOption === "Industry") {
					return industryScoreValues;
				}
				else if (geographyScoreValues && selectOption === "Geography") {
					return geographyScoreValues;
				}
				else {
					return [0, 0, 0, 0, 0];
				}
			}
			else if (type === 'benchmarkscore') {
				const resultType = radioSelected === "average" ? "benchmarkScore" : "topQuartileScore"

				const organisationBenchMarkScore = allOrganizations[0][resultType];
				const sizeBenchMarkScore = size.length > 0 ? size[0][resultType] : 0;
				const industryBenchMarkScore = industry.length > 0 ? industry[0][resultType] : 0;
				const geographyBenchMarkScore = geography[0][resultType];

				if (organisationBenchMarkScore && (selectOption === "AllOrg" || selectOption == "")) return organisationBenchMarkScore;
				else if (sizeBenchMarkScore && selectOption === "Size") return sizeBenchMarkScore;
				else if (industryBenchMarkScore && selectOption === "Industry") return industryBenchMarkScore;
				else if (geographyBenchMarkScore && selectOption === "Geography") return geographyBenchMarkScore;
				else return 0;
			}
		}
		else {
			return type === "chart" ? [0, 0, 0, 0, 0] : 0;
		}
	}

	const data = {
		// labels: [
		//   ["Stakeholder Inclusion"],
		//   ["Emotion & Intuition"],
		//   ["Mission & Purpose"],
		//   ["Technology & Innovation"],
		//   ["Intellect & Insight"]
		// ],
		datasets: [
			{
				label: "Your Score",
				fill: true,
				backgroundColor: [
					'rgba(122,122,111,0.8)',
					'rgba(180,85,170,0.8)',
					'rgba(12,78,226,0.8)',
					'rgba(92,184,114,0.8)',
					'rgba(106,64,204,0.8)',
				],
				borderColor: [
					'rgba(122,122,111,0.8)',
					'rgba(180,85,170,0.8)',
					'rgba(12,78,226,0.8)',
					'rgba(92,184,114,0.8)',
					'rgba(106,64,204,0.8)',
				],
				pointBackgroundColor: [
					'rgba(122,122,111)',
					'rgba(180,85,170)',
					'rgba(12,78,226)',
					'rgba(92,184,114)',
					'rgba(106,64,204)',
				],
				hoverPointBackgroundColor: [
					'rgba(122,122,111)',
					'rgba(180,85,170)',
					'rgba(12,78,226)',
					'rgba(92,184,114)',
					'rgba(106,64,204)',
				],
				data: props.lroScoreValues ? props.lroScoreValues : []
			},
			{
				label: "Benchmark Score",
				borderColor: "rgba(255, 255, 255, 0.8)",
				hoverPointBackgroundColor: "#fff",
				data: buttonClick('chart'),
			}
		],
	}

	const scoreoptions = {
		onHover: function (d) {
			var chart = document.getElementById('chartjs-tooltip')
			if (chart) {
				chart.style.animationName = 'hover';
			}
		},
		onClick: function (data) {
			var chart = document.getElementById('chartjs-tooltip')
			if (chart) {
				if (chart.style.animationName === 'on') {
					chart.style.animationName = 'off';
				}
				else {
					chart.style.animationName = 'on';
				}
			}
		},
		segmentShowStroke: false,
		tooltips: {
			enabled: false,
			custom: function (tooltipModel) {
				// Tooltip Element
				var tooltipEl = document.getElementById('chartjs-tooltip');

				// Create element on first render
				if (!tooltipEl) {
					tooltipEl = document.createElement('div');
					tooltipEl.id = 'chartjs-tooltip';
					tooltipEl.innerHTML = '<table></table>';
					document.body.appendChild(tooltipEl);
				}

				// Hide if no tooltip
				if (tooltipModel.opacity === 0) {
					tooltipEl.style.opacity = "0";
					return;
				}

				// Set caret Position

				var top;
				if (tooltipModel.yAlign == 'above') {
					top = tooltipModel.y - tooltipModel.caretHeight - tooltipModel.caretPadding;
				} else {
					top = tooltipModel.y + tooltipModel.caretHeight + tooltipModel.caretPadding;
				}

				tooltipEl.classList.remove('above', 'below', 'no-transform');
				if (tooltipModel.yAlign) {
					tooltipEl.classList.add(tooltipModel.yAlign);
				} else {
					tooltipEl.classList.add('no-transform');
				}

				function getBody(bodyItem) {
					return bodyItem.lines;
				}

				// Set Text
				if (tooltipModel.body) {
					var titleLines = tooltipModel.title || [];
					var bodyLines = tooltipModel.body.map(getBody);

					var innerHtml = '<thead>';

					titleLines.forEach(function (title) {
						innerHtml += '<tr><th>' + title + '</th></tr>';
					});
					innerHtml += '</thead><tbody>';
					var bodyText = [];
					if (tooltipModel.dataPoints[0].index === 0) {
						bodyText.push('<b>Stakeholder Inclusion:</b> Safeguarding trust and positive impact for all by standing in the shoes of stakeholders when making decisions, and fostering an inclusive environment where diverse individuals have a voice and feel they belong.');
					}
					if (tooltipModel.dataPoints[0].index === 1) {
						bodyText.push('<b>Emotion & Intuition:</b> Unlocking commitment and creativity by being truly human, showing compassion, humility and openness.');
					}
					if (tooltipModel.dataPoints[0].index === 2) {
						bodyText.push('<b>Mission & Purpose</b>: Advancing common goals by inspiring a shared vision of sustainable prosperity for the organization and its stakeholders.');

					}
					if (tooltipModel.dataPoints[0].index === 3) {
						bodyText.push('<b>Technology & Innovation:</b> Creating new organizational and societal value by innovating responsibly with emerging technology.');

					}
					if (tooltipModel.dataPoints[0].index === 4) {
						bodyText.push('<b>Intellect & Insight:</b> Finding ever-improving paths to success by embracing continuous learning and knowledge exchange.');
					}

					bodyLines = [];
					bodyLines.push(bodyText);


					bodyLines.forEach(function (body, i) {
						var colors = tooltipModel.labelColors[i];
						var style = 'background:' + colors.backgroundColor;
						style += '; border-color:' + colors.borderColor;
						style += '; border-width: 2px';
						var span = '<span style="' + style + '"></span>';
						innerHtml += '<tr><td>' + span + body + '</td></tr>';
					});
					innerHtml += '</tbody>';

					var tableRoot = tooltipEl.querySelector('table');
					tableRoot.innerHTML = innerHtml;
				}

				// `this` will be the overall tooltip
				var position = this._chart.canvas.getBoundingClientRect();

				// Display, position, and set styles for font
				tooltipEl.style.opacity = "1";
				tooltipEl.style.position = 'absolute';
				tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 80 + 'px';
				tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
				tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
				tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
				tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
				tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
				tooltipEl.style.pointerEvents = 'none';
				tooltipEl.style.backgroundColor = "rgba(0,0,0)";
				tooltipEl.style.color = "rgba(255,255,255)";


			}
		},
		plugins: {
			beforeInit: function (chart, options) {
				chart.legend.afterFit = function () {
					this.height += 100; // must use `function` and not => because of `this`
				};
			},
			datalabels: {
				// display: false,
				align: 'center',
				anchor: 'end',
				// offset: context => (context.dataset.label==="Benchmark Score") ? 4 : -8,
				backgroundColor: context => (context.dataset.label === "Benchmark Score") ? 'white' : 'rgba(0, 0, 0, 0.5)',
				borderColor: context => (context.dataset.label === "Benchmark Score") ? 'rgb(0,65,240)' : 'rgba(0, 0, 0, 0.5)',
				borderRadius: 30,
				borderWidth: 1,
				color: context => (context.dataset.label === "Benchmark Score") ? 'rgb(0,65,240)' : 'white',
				font: {
					weight: 'bold',
				},
				fontWeight: 'bold',
				padding: 5,
				textAlign: 'center'
			}
		},
		aspectRatio: 1,
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			// display: false,
			// fontSize:14,
			position: "bottom",
			onClick: function (e) {
				e.stopPropagation();
			},
			// fullWidth: true,
			labels: {
				// maxWidth: 400,
				boxWidth: 10,
				display: false,
				// padding:25,
				// usePointStyle: true,
				// fontSize: 14,
				fontColor: "#FFFFFF",
				fontStyle: '600',
				fontFamily: "Graphik-Regular"
			}
		},
		title: {
			display: false,
			text: 'Chart.js Radar Chart'
		},
		scale: {
			angleLines: {
				color: '#FFFFFF',
				lineWidth: 2
			},
			gridLines: {
				lineWidth: [1, 1, 1, 1, 1],
				ticklength: 0,
				color: [
					'rgba(255,255,255,0.1)',
					'rgba(255,255,255,0.1)',
					'rgba(255,255,255,0.1)',
					'rgba(255,255,255,0.1)',
					'rgba(255,255,255,0.1)',
				]
			},
			ticks: {
				beginAtZero: true,
				min: 0,
				max: 100,
				stepSize: 20,
				// showLabelBackdrop: true,
				padding: 50,
				fontColor: "#484856"
			}
		}
	}

	const barData = {
		labels: [["Human", "Connections"], ["Collective", "Intelligence"]],
		datasets: [
			{
				label: "Your Score",
				data: [
					props.results?.scoreTagDTO ? props.results.scoreTagDTO.hcScore : 0,
					props.results?.scoreTagDTO ? props.results.scoreTagDTO.ciScore : 0					
				],
				backgroundColor: ["rgba(161, 0, 255, 0.5)", "rgba(161, 0, 255, 0.5)"],
				hoverBackgroundColor: ["rgba(161, 0, 255, 1)", "rgba(161, 0, 255, 1)"],
				borderColor: ["rgba(161, 0, 255, 1)", "rgba(161, 0, 255, 1)"],
				borderWidth: 1,
				datalabels: {
					anchor: "end",
					align: "top",
				},
			},
			{
				label: "Average Score",
				data: [57, 47],
				backgroundColor: ["rgba(230, 230, 220, 0.5)", "rgba(230, 230, 220, 0.5)"],
				hoverBackgroundColor: ["rgba(230, 230, 220, 1)", "rgba(230, 230, 220, 1)"],
				borderColor: ["rgba(230, 230, 220, 1)", "rgba(230, 230, 220, 1)"],
				borderWidth: 1,
				datalabels: {
					anchor: "end",
					align: "top",
				},
			}
		]
	}

	const barOptions = {
		onHover: function (data) {
			var chart = document.getElementById('barChart-tooltip')
			if (chart) {
				chart.style.animationName = 'hover';
			}
		},
		onClick: function (data) {
			var chart = document.getElementById('barChart-tooltip')
			if (chart) {
				if (chart.style.animationName === 'on') {
					chart.style.animationName = 'off';
				}
				else {
					chart.style.animationName = 'on';
				}
			}
		},
		aspectRatio: 1.5,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					min: 0,
					max: 100,
					stepSize: 20,
				}
			}],
		},
		tooltips: {
			enabled: false
		},
		legend: {
			display: false
		}
	}

	const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRadioSelected(event.target.value);
	}

	return (
		<React.Fragment>
			<div id="resultspage">
				<Grid container className={classes.xsCenterAlign}>
					<Grid container md={12} sm={12} xs={12} >
						<Grid item md={6} sm={6} xs={12} style={{ marginTop: '1rem' }}>
							<Grid md={12} sm={12} xs={12}>
								<label className="title">Sustainable Organization Diagnostic - Your Results</label>
							</Grid>
							<Grid item md={12} sm={12} xs={12} style={{ marginTop: '1.2rem' }}>
								<Grid md={12} sm={12} xs={12}>
									<Hidden only={['sm', 'md', 'lg', 'xl']}>
										<Grid container>
											<Grid item xs={11}>
												<Button variant="contained" color="primary" className={classes.colorButton} onClick={() => props.onSend()}>
													Download Your Report
												</Button>
											</Grid>
											<Grid item xs={11}>
												<Button variant="contained" style={{ padding: '5px 10px' }} color="primary" className={classes.colorButton}
													onClick={() => {
														localStorage.setItem('onclickrestartDiagnostic', 'true')
														history.push('/landing');
													}}>
													RESTART DIAGNOSTIC
												</Button>
											</Grid>
										</Grid>
									</Hidden>
									<Hidden only="xs">
										<Grid item sm={12}>
											<Button variant="contained" color="primary" className={classes.colorButton} onClick={() => props.onSend()}>
												Download Your Report
											</Button>
											<Button variant="contained" color="primary" className={classes.colorButton}
												onClick={() => {
													localStorage.setItem('onclickrestartDiagnostic', 'true');
													history.push('/landing');
												}}>
												RESTART DIAGNOSTIC
											</Button>
										</Grid>
									</Hidden>
								</Grid>
								<Grid md={12} sm={12} xs={12} style={{ marginTop: '1.25rem', marginRight: '1.8rem' }}>
									<span className="resultsText" style={navigator.userAgent.match(/iPad/i) != null ? { height: 'auto' } : {}}>
										Your scores have been aligned to one of ten maturity levels of Sustainability DNA.
										This shows you the typical strengths and development areas for these types of organizations.
										It may not perfectly reflect your scores. We invite you to restart the diagnostic from another
										perspective - either personal or organizational - to compare how your individual values may differ
										from your organization's.
									</span>
								</Grid>
							</Grid>
							<br></br>
							<Grid container md={12} sm={12} xs={11} className="textBorder" >
								<Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '15px', paddingRight: '15px' }}>
									<p className="textFont">{props.results ? props.results.archetypeName : ""} ​</p>
									<p className="resultsText" style={navigator.userAgent.match(/iPad/i) != null ? { height: 'auto' } : {}}>{props.results ? props.results.summaryText : ""} </p>
								</Grid>
							</Grid>
							<Grid container md={12} sm={12} xs={11} className="textBorder">
								<Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '15px', paddingRight: '15px' }}>
									<p className="textFont">What are the common strengths for this maturity level?</p>
									<p className="resultsText" style={navigator.userAgent.match(/iPad/i) != null ? { height: 'auto' } : {}} id="strengthtext">{props.results ? textChange(props.results.strengthsText, 'strength') : ""}</p>
								</Grid>
							</Grid>
							<Grid container md={12} sm={12} xs={11} className="textBorder">
								<Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '15px', paddingRight: '15px' }}>
									<p className="textFont">What are the common areas to develop for this maturity level?​</p>
									<p className="resultsText" style={navigator.userAgent.match(/iPad/i) != null ? { height: 'auto' } : {}} id="developtext">{props.results ? textChange(props.results.areasToDevelopText, 'areas') : ""}</p>
								</Grid>
							</Grid>
							<Hidden only="xs">
								<Grid item sm={12} className={classes.xsContactInfoMargin} style={{ paddingTop: "1rem" }}>
									<label className="contactText">
										Read our report&nbsp;
										<a style={{ color: "#FFFFFF", fontSize: "0.813rem" }} target='_blank' href="https://www.accenture.com/us-en/insights/sustainability/sustainable-organization">Shaping the Sustainable Organization</a>
										&nbsp;to find out more, or get in touch with&nbsp;
										<a style={{ color: "#FFFFFF", fontSize: "0.813rem" }} target='_blank' href="mailto:sustainableorg@accenture.com?subject=Building Sustainable Organizations Query">sustainableorg@accenture.com</a>
										&nbsp;if you have any questions.
									</label>
								</Grid>
							</Hidden>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<Grid container alignItems="center" style={{ height: "100%" }}>
								<Grid item md={12} sm={12} xs={12}>	
									<Grid md={12} sm={12} xs={11} className={classes.tabsContainer}>
										<Tabs
											value={tabSelected}
											TabIndicatorProps={{ className: classes.indicator }}
											onChange={(event, value) => setSelectedTab(value)}
											variant="fullWidth"
											aria-label="basic tabs example"
										>
											<Tab disableTouchRipple label={<span className={classes.customStyleOnTab}>Sustainability DNA</span>} />
											<Tab classes={{ root: classes.tabRoot }} disableTouchRipple label={<span className={classes.customStyleOnTab}>Human Connections &#38; Collective Intelligence</span>} />
										</Tabs>
									</Grid>
									{
										tabSelected === 1 ?
										<React.Fragment>
											<Grid md={12} sm={12} xs={11} className={classes.tagMessageContainer}>
												<p className="textFont" style={{ marginBlockStart: ".5rem", marginBlockEnd: ".5rem" }}>Is your Sustainability DNA driving Human Connections or Collective Intelligence?​</p>
												<span className="resultsText" style={{ height: 'auto' }}>
													{ props.results?.scoreTagDTO ? props.results.scoreTagDTO.tagMessage : ""}
												</span>
											</Grid>
											<Grid className={classes.barGraphBox}>
												<label className={classes.textFont}>Human Connections and Collective Intelligence Scores</label>
												<Grid item xs={12} className={classes.barChartContainer}>
													<Grid container justifyContent="center" style={{ paddingLeft: "4rem" }}>
														{
															[
																{ "name": "Your Score", "color": "rgba(161, 0, 255, 0.5)" },
																{ "name": "Average Score", "color": "rgba(230, 230, 220, 0.5)" }
															].map(label => {
																return (
																	<span className={classes.legendLabel}>
																		<div className={classes.square} style={{ backgroundColor: `${label.color}`}}></div>
																		<label className={classes.legend}>{label.name}</label>
																	</span>
																)
															})
														}
													</Grid>
													<Bar data={barData} options={barOptions}></Bar>
												</Grid>
											</Grid>
										</React.Fragment>
										:
										<React.Fragment>
											<Hidden only={'xs'}>
												<Grid container>
													<Grid item xs={12} className={classes.contactText} style={{ padding: "0 1rem" }}>
														<div className={classes.legendLabel} style={{ flexWrap: "wrap", justifyContent: "left" }}>
															<label className="contactText">Compare my results to:</label>
															<span className={classes.legendLabel}>
																<span className={classes.legendLabel}>
																	<Radio
																		checked={radioSelected === 'average'}
																		onChange={onRadioChange}
																		value="average"
																		name="radio-buttons"
																		inputProps={{ 'aria-label': 'Average Score' }}
																		size="small"
																		className={classes.radioButton}
																	/>
																	<label className="contactText">Average scores</label>
																</span>
																<span className={classes.legendLabel}>
																	<Radio
																		checked={radioSelected === 'topQuartile'}
																		onChange={onRadioChange}
																		value="topQuartile"
																		name="radio-buttons"
																		inputProps={{ 'aria-label': 'Top Quartile Score' }}
																		size="small"
																		className={classes.radioButton}
																	/>
																	<label className="contactText">Top quartile scores</label>
																</span>
															</span>
														</div>
													</Grid>
												</Grid>
												<Grid container md={12} sm={12} className={classes.contactText}>
													<Grid item xs={12} sm={12} md={12} style={{ paddingBottom: '1rem' }}>
														<label className="contactText">Click to see your benchmark:</label>
													</Grid>
													<Grid item xs={11} sm={3} md={3} onClick={() => props.onClickLandingInfo('AllOrg')}>
														<div className={props.selectOption === "AllOrg" || props.selectOption == "" ? 'col textBackground alink' : "col alink"}> All Organizations</div>
													</Grid>
													<Grid item xs={11} sm={3} md={3} onClick={() => { props.landingdetails?.industryValue !== 'Other' ? props.onClickLandingInfo('Industry') : '' }}>
														<Tooltip placement="top" enterTouchDelay={0} title={props.landingdetails?.industryValue != 'Other' ? "Industry" : "Industry benchmark not available as you selected 'Other'"}>
															<div className={props.selectOption === "Industry" ? 'col textBackground alink' : "col alink"}>{props.landingdetails?.industryValue}</div>
														</Tooltip>
													</Grid>
													<Grid item xs={11} sm={3} md={3} onClick={() => props.onClickLandingInfo('Geography')}>
														<Tooltip placement="top" enterTouchDelay={0} title="Geography">
															<div className={props.selectOption === "Geography" ? 'col textBackground alink' : "col alink"}>{props.landingdetails?.geographyValue}</div>
														</Tooltip>
													</Grid>
													<Grid item xs={11} sm={3} md={3} onClick={() => { props.landingdetails?.sizeValue != 'N/A' ? props.onClickLandingInfo('Size') : ""; }}>
														<Tooltip placement="top" enterTouchDelay={0} title={props.landingdetails?.sizeValue != 'N/A' ? "Annual Revenue" : "Annual Revenue benchmark not available as you selected 'N/A'"}>
															<div className={props.selectOption === "Size" ? ' col textBackground alink' : "col alink"}>{props.landingdetails?.sizeValue}</div>
														</Tooltip>
													</Grid>
												</Grid>
												<Grid container md={12} sm={12} xs={12} justify='flex-start' style={{ paddingRight: '0.188rem' }} >
													<div className="allOrgFont">
														<div style={{ paddingRight: '0.5rem' }} className="resultsHeading">{((props.selectOption === "AllOrg" || props.selectOption == "") ? "ALL ORGANIZATIONS" : (props.selectOption === "Size" ? "ANNUAL REVENUE" : (props.selectOption === "Industry" ? "INDUSTRY" : (props.selectOption === "Geography" ? "GEOGRAPHY" : ""))))}</div>
														<div style={{ border: '1px solid #FFFFFF', height: "1.25rem", background: '#FFFFFF' }}></div>
														<div className="comments text-white">
															Benchmark Score &nbsp;
															<div className="markText" style={{ color: 'rgb(0,65,240)', background: "white" }}>
																<span className="scoreText">{buttonClick('benchmarkscore')}</span>
															</div>
														</div>
														<div className="comments text-white">
															Your Score &nbsp;
															<div className="markText" style={{ color: 'white', background: "rgba(0, 0, 0, 0.5)", borderColor: "rgba(0, 0, 0, 0.5)" }}>
																<span className="scoreText">{props.results ? props.results.userERLIScore : ""}</span>
															</div>
														</div>
													</div>
												</Grid>
											</Hidden>
											<Hidden only={['sm', 'md', 'lg', 'xl']}>
												<Grid style={{ marginTop: "1rem" }}>
													<span className="resultsHeading">
														{((props.selectOption === "AllOrg" || props.selectOption == "") ? "ALL ORGANIZATIONS"
															: (props.selectOption === "Size" ? "SIZE"
																: (props.selectOption === "Industry" ? "INDUSTRY"
																	: (props.selectOption === "Geography" ? "GEOGRAPHY"
																		: ""))))}
													</span>
												</Grid>
												<Grid container xs={11} justify='space-evenly' style={{ marginTop: '1rem' }}>
													<Grid item>
														<Grid container alignItems="center">
															<div className="xsScores">
																Benchmark Score &nbsp;
																<div className="markText" style={{ color: 'rgba(0,65,240)', background: "white" }}>
																	<span className="xsScoreText">{buttonClick('benchmarkscore')}</span>
																</div>
															</div>
														</Grid>
													</Grid>
													<Grid item style={{ alignItems: 'right' }}>
														<Grid container alignItems="center">
															<div className="xsScores">
																Your Score &nbsp;
																<div className="markText" style={{ color: 'white', background: "rgba(0, 0, 0, 0.5)", borderColor: "rgba(0, 0, 0, 0.5)" }}>
																	<span className="xsScoreText">{props.results ? props.results.userERLIScore : ""}</span>
																</div>
															</div>
														</Grid>
													</Grid>
												</Grid>
											</Hidden>
											<Grid item xs={12} className={classes.polarGraph}>
												<Polar data={data} options={scoreoptions}></Polar>
											</Grid>
											<Grid className={classes.paddingLeft}>
													{
														[
															{ "name": "Stakeholder Inclusion", "color": "rgba(122,122,111,1)" },
															{ "name": "Emotion & Intuition", "color": "rgba(180,85,170,1)" },
															{ "name": "Mission & Purpose", "color": "rgba(12,78,226,1)" },
															{ "name": "Technology & Innovation", "color": "rgba(92,184,114,1)" },
															{ "name": "Intellect & Insight", "color": "rgba(106,64,204,1)" }
														].map(label => {
															return (
																<span className={classes.legendLabel}>
																	<div className={classes.pentagon} style={{ borderColor: `${label.color} transparent`}}></div>
																	<label className={classes.legend}>{label.name}</label>
																</span>
															)
														})
													}
											</Grid>
										</React.Fragment>
									}
								</Grid>	
							</Grid>
						</Grid>
						{
							tabSelected === 0 &&
							<Hidden only={['sm', 'md', 'lg', 'xl']}>
								<Grid container>
									<Grid item xs={12} sm={12} md={4} className={classes.contactText}>
										<label>Compare my results to:</label>
									</Grid>
									<Grid item xs={12} sm={12} md={8} className={classes.contactText}>
										<span className={classes.legendLabel}>
											<Radio
												checked={radioSelected === 'average'}
												onChange={onRadioChange}
												value="average"
												name="radio-buttons"
												inputProps={{ 'aria-label': 'Average Score' }}
												size="small"
												className={classes.radioButton}
											/>
											<label>Average scores</label>
										</span>
										<span className={classes.legendLabel}>
											<Radio
												checked={radioSelected === 'topQuartile'}
												onChange={onRadioChange}
												value="topQuartile"
												name="radio-buttons"
												inputProps={{ 'aria-label': 'Top Quartile Score' }}
												size="small"
												className={classes.radioButton}
											/>
											<label>Top quartile scores</label>
										</span>
									</Grid>
								</Grid>
							</Hidden>
						}
					</Grid>
					<Grid container xs={12} md={12} sm={12} style={{ paddingBottom: '10px' }}>
						<Grid container xs={12} sm={12} md={12} spacing={2} alignItems="center">
						</Grid>
						{
							tabSelected === 0 &&
							<Hidden only={['sm', 'md', 'lg', 'xl']}>
								<Grid container xs={12} md={6} sm={6} className={classes.contactText}>
									<Grid item xs={12} sm={12} md={12} style={{ paddingRight: '5px', paddingBottom: '3px' }}>
										<label className="contactText">Click to see your benchmark:</label>
									</Grid>
									<Grid item xs={11} sm={3} md={3} onClick={() => props.onClickLandingInfo('AllOrg')}>
										<div className={props.selectOption === "AllOrg" || props.selectOption == "" ? 'col textBackground alink' : "col alink"}> All Organizations</div>
									</Grid>
									<Grid item xs={11} sm={2} md={2} onClick={() => { props.landingdetails?.industryValue !== 'Other' ? props.onClickLandingInfo('Industry') : '' }}>
										<Tooltip placement="top" enterTouchDelay={0} title={props.landingdetails?.industryValue != 'Other' ? "Industry" : "Industry benchmark not available as you selected 'Other'"}>
											<div className={props.selectOption === "Industry" ? 'col textBackground alink' : "col alink"}>{<label>{props.landingdetails?.industryValue}</label>}</div>
										</Tooltip>
									</Grid>
									<Grid item xs={11} sm={2} md={2} onClick={() => props.onClickLandingInfo('Geography')}>
										<Tooltip placement="top" enterTouchDelay={0} title='Geography'>
											<div className={props.selectOption === "Geography" ? 'col textBackground alink' : "col alink"}>{<label>{props.landingdetails?.geographyValue}</label>}</div>
										</Tooltip>
									</Grid>
									<Grid item xs={11} sm={2} md={2} onClick={() => { props.landingdetails?.sizeValue != 'N/A' ? props.onClickLandingInfo('Size') : ""; }}>
										<Tooltip placement="top" enterTouchDelay={0} title={props.landingdetails?.sizeValue != 'N/A' ? 'Annual Revenue' : "Annual Revenue benchmark not available as you selected 'N/A'"}>
											<div className={props.selectOption === "Size" ? ' col textBackground alink' : "col alink"}>{<label>{props.landingdetails?.sizeValue}</label>}</div>
										</Tooltip>
									</Grid>
								</Grid>
							</Hidden>
						}
						<Hidden only={['sm', 'md', 'lg', 'xl']}>
							<Grid container>
								<Grid item xs={12} sm={12} md={12}
									className={classes.xsContactInfoMargin}
									style={{ paddingLeft: '10px' }}>
									<label className="contactText">
										Read our report&nbsp;
										<a style={{ color: "#FFFFFF", fontSize: "0.813rem" }} target='_blank' href="https://www.accenture.com/us-en/insights/sustainability/sustainable-organization">Shaping the Sustainable Organization</a>
										&nbsp;to find out more, or get in touch with&nbsp;
										<a style={{ color: "#FFFFFF", fontSize: "0.813rem" }} target='_blank' href="mailto:sustainableorg@accenture.com?subject=Building Sustainable Organizations Query">sustainableorg@accenture.com</a>
										&nbsp;if you have any questions.
									</label>
								</Grid>
							</Grid>
						</Hidden>
					</Grid>
				</Grid>
				<Modal
					isShown={false}
					hide={props.onCancel}
					page="Results"
					headerText='My Diagnostic Report & Possible Follow-Up'
					showPopup={props.showPopup}
					showConfirmPopup={false}
					modalContent={<>
						<LoadingOverlay
							active={props.loadingIcon}
							spinner
							text='Downloading Report'
						>
							<ConfirmationModal
								onStartSurveyClicked={props.onStartSurveyClicked}
								message=""
								page="Results"
								termsAccepted={props.termsAccepted}
								isAccepted={props.isAccepted}
								onClicked={props.onClicked}
								onTermChanged={props.onTermChanged}
								userNameChanged={props.userNameChanged}
								isStartSurveyDisabled={props.isStartSurveyDisabled}
								userName={props.userName}
								lastName={props.lastName}
								country={props.country}
								roleName={props.roleName}
								seniority={props.landingdetails?.seniorityValue}
								organisationName={(props.organisationName ? props.organisationName : props.landingdetails?.organisationName)}
								disableOrgText={props.landingdetails?.organisationName ? true : false}
								emailAddress={props.emailAddress}
								errorMessage={props.errorMessage}
								usererrorMessage={props.usererrorMessage}
								lastNameerrorMessage={props.lastNameerrorMessage}
								roleerrorMessage={props.roleerrorMessage}
								organisationerrorMessage={props.organisationerrorMessage}
								emailValidation={props.emailValidation}
								textValidation={props.textValidation}
								onCancel={props.onCancel}
								countries={countries} />
						</LoadingOverlay></>} />
				<Modal
					isShown={false}
					hide={toggle}
					page="Success"
					headerText="Confirmation"
					showPopup={false}
					showConfirmPopup={props.showConfirmPopup}
					modalContent={<ConfirmationModal
						onStartSurveyClicked={props.onStartSurveyClicked}
						message={props.confirmMessage}
						page="Success"
						termsAccepted={props.termsAccepted}
						isAccepted={props.isAccepted}
						onClicked={props.onClicked}
						onTermChanged={props.onTermChanged}
						userNameChanged={props.userNameChanged}
						isStartSurveyDisabled={props.isStartSurveyDisabled}
						userName={props.userName}
						roleName={props.roleName}
						seniority={props.landingdetails?.seniorityValue}
						organisationName={props.landingdetails?.organisationName}
						emailAddress={props.emailAddress}
						errorMessage={props.errorMessage}
						usererrorMessage={props.usererrorMessage}
						roleerrorMessage={props.roleerrorMessage}
						organisationerrorMessage={props.organisationerrorMessage}
						emailValidation={props.emailValidation}
						textValidation={props.textValidation}
						onCancel={props.onCancel} />} />
			</div>
		</React.Fragment>
	)
}
