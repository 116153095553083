import { Button, IconButton,Grid, Paper, TextField,makeStyles,createStyles,Theme } from "@material-ui/core";
import React from "react";
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import IAnsweredQuestion from "../Common/IAnsweredQuestion";
import CloseIcon from '@material-ui/icons/Close';
import { lorem } from "faker";
import logo from '../public/img/comment_icon.svg';
import Divider from '@material-ui/core/Divider';

interface IAnswerGradeProps {
    number: number
    isSelected: boolean
    onClick: (number: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    answerGrade: {
        display: 'flex',
        width: "2.7rem",
        height: "2.7rem",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        border: '1px solid white',
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: "2.0rem",
            height: "2.0rem",
        },
        // [theme.breakpoints.down('xs')]: {
        //     width: "2.7rem",
        //     height: "2.7rem",
        // }

    }
  }),
);



function AnswerGrade(props: IAnswerGradeProps){
    const classes = useStyles();
    const isSelectedClass = (props.isSelected? "selected": "")
    const isSelectedAnswer = (props.isSelected? "selectanswerFont": "answerFont")
    return <div className={`${classes.answerGrade} ${isSelectedClass}`} onClick={() => props.onClick(props.number)}>
            <span className={`bodyText ${isSelectedAnswer} noHighlight`}>{props.number}</span>
        </div>
}

export interface IQuestionComponentProps {
 
    question: IAnsweredQuestion
    onQuestionChanged: (newQuestion: IAnsweredQuestion) => void
    onSubmitClick(): void;
    isSubmitDisabled: boolean ;
}

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        marginBottom: '1.0rem',
        marginLeft: '0.3rem',
        marginTop: '0.3rem',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0rem',
            marginTop: '0rem',
        }
    },
    gridBorder: {
        border: '1px solid white',
        borderWidth:'1px 1px 1px 1px',
        [theme.breakpoints.down('xs')]: {
            borderWidth:'1px 1px 0px 1px',
        }
    },
    gridBorder1: {
        border: '1px solid white',
        borderWidth:'1px 1px 1px 0px',
        [theme.breakpoints.down('xs')]: {
            borderWidth:'0px 1px 1px 1px',
            marginTop: '-2rem'
        }
    },
    dividerBorder: {
        border: '2px solid white',
        borderWidth:'0px 0px 2px 0px',
        width: "100%",
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            // display:'block'
        }
    }
  }),
);




export default function QuestionComponent(props: IQuestionComponentProps){
    const classes1 = useStyles1();
    const classes = makeStyles(t => createStyles({
      
        inputInnerPadding: {
            
            fontSize:'14px !important'
            
            
        },
    
    }))()

    const onGradeClick = (grade: number) => {
        const newQuestion = Object.assign(props.question, {scale: grade})
        props.onQuestionChanged(newQuestion)
    }

    const onCommentAdd = () => {
        props.onQuestionChanged({...props.question, closePopup: ""})
    }

    const onCommentChange = (comments: string) => {
        props.onQuestionChanged({...props.question, comments})
    }

    const onCommentRemove = () => {
        const newQuestion = Object.assign({}, props.question)
        newQuestion.closePopup = undefined
        if(newQuestion?.comments?.trim() == "" )
           newQuestion.comments = "";

        props.onQuestionChanged(newQuestion)
    }
 


    // return <div className="question" >
       
    //             <div className="question-indicator">
    //                 <p className="bodyText newFont">Q{props.question.questionSeqNum}.</p>
    //             </div>
    //             <div className="question-text">
    //                 <p className="bodyText instructions">{props.question.question}</p>
    //             </div>
    //             <div className="question-comment">
                
    //             <Button
    //                 classes={{
    //                     label: "comments text-blue",
    //                     endIcon: "text-white"
    //                 }}
    //                 onClick={() => onCommentAdd()}
    //                 endIcon={props.question.comments !== undefined &&  props.question.comments !== ""  ? <img src={logo}/> : null }
    //                 disableRipple 
    //                 style={{textTransform: "none"}}
    //             >Comments</Button>
    //        </div>
        
   
    //             <div className="question-right">
    //                 <div className="question-grade">
    //                     {new Array(7).fill("").map((v, i)=><AnswerGrade number={i + 1}
    //                         key={i.toString()}
    //                         isSelected={i + 1 === props.question.scale}
    //                         onClick={() => onGradeClick(i + 1)}/>
    //                     )}
    //                 </div>
    //                 {
    //                     props.question.closePopup !== undefined? 
    //                     <div className="question-comment-container bodyText">
    //                         <Paper className="question-comment-paper">
    //                             <div className="title-area">
    //                                 <b className="comments" style={{lineHeight:'18px'}}>Comments</b>
    //                                 <IconButton className="action"
    //                                     onClick={() => onCommentRemove()}
    //                                 >
    //                                     <CloseIcon/>
    //                                 </IconButton>
    //                             </div>
    //                             <TextField
    //                             multiline
    //                                 placeholder="Add optional comments to explain your response (this will not impact your Results)"
    //                                 rowsMax={3}
    //                                 InputProps={{disableUnderline: true}}
    //                                 inputProps={
    //                                     {
    //                                         className: `${classes.inputInnerPadding}`
                                        
    //                                     }   
    //                                 }
    //                                 className='commentsText'
    //                                 value={props.question.comments}
    //                                 onChange={({target: {value}}) => onCommentChange(value)}
    //                             />
    //                         </Paper>
    //                     </div>
    //                     : null
    //                 }
                    
    //             </div>
       
    // </div>
    return <div >
        <Grid container spacing={3} sm={12} xs={12} md={12} className = {classes1.root} >
            <Grid item container sm={5} xs={11} md={5} className = {classes1.gridBorder}>
            <span style = {{display:'flex'}}>  
               <p>   <span className="bodyText newFont"> Q{props.question.questionSeqNum}.&nbsp;&nbsp;&nbsp;</span> </p> 
               <p>   <span className="bodyText instructions">{props.question.question}</span> </p> 
           </span>  
               
                
               
               <br/>
               <br/>
              
               <Grid>
               <div className="question-comment">
                
                 <Button
                     classes={{
                       label: "comments text-blue",
                       endIcon: "text-white"
                     }}
                    onClick={() => onCommentAdd()}
                    endIcon={props.question.comments !== undefined &&  props.question.comments.trim() !== ""  ? <img src={logo}/> : null }
                    disableRipple 
                    style={{textTransform: "none"}}
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Comments</Button>
           </div>
           </Grid>
           
            </Grid>
            <Grid item sm={6} xs={11} md={6} className = {classes1.gridBorder1}>
             <div className="question-right">
                   <div className="question-grade">
                         {new Array(7).fill("").map((v, i)=><AnswerGrade number={i + 1}
                             key={i.toString()}
                             isSelected={i + 1 === props.question.scale}
                             onClick={() => onGradeClick(i + 1)}/>
                         )}
                     </div>
                     {
                         props.question.closePopup !== undefined? 
                         <div className="question-comment-container bodyText">
                             <Paper className="question-comment-paper" >
                                 <div className="title-area">
                                     <b className="comments" style={{lineHeight:'18px'}}>Comments</b>
                                     <IconButton className="action"
                                         onClick={() => onCommentRemove()}
                                     >
                                         <CloseIcon/>
                                     </IconButton>
                                 </div>
                                 <TextField
                                 multiline
                                     placeholder="Add optional comments here (this will not impact your results). Do not share personal data about yourself or others here."
                                     rowsMax={3}
                                     InputProps={{disableUnderline: true}}
                                     inputProps={
                                         {
                                             className: `${classes.inputInnerPadding}`,
                                             maxLength: 1024
                                         }   
                                     }
                                     className='commentsText'
                                     style={{ display: 'flex'}}
                                     value={props.question.comments}
                                     onChange={({target: {value}}) => onCommentChange(value)}
                                 />
                             </Paper>
                         </div>
                         : null
                     }
                    
                 </div>
                 <Divider className = {classes1.dividerBorder}></Divider>
            </Grid>
        </Grid>
    </div>
}