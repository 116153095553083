import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/green";
import { FormControlLabel, FormGroup, Grid } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';


export interface ILandingPageFooterProps {
  
  
}

//Checkbox
const WhiteCheckbox = withStyles({
    root: {
        color: grey[50],
        '&$checked': {
            color: grey[50],
        },
        transform: "scale(1.55)"
    },
    checked: {},
})(Checkbox);

export default function LandingPageFooter(props: ILandingPageFooterProps) {

    return (
        <div></div>
   /*      <Grid container style={{textAlign: "left"}}>
            <Grid item xs={12} md={4}>
            <FormGroup>
                <FormControlLabel style={{margin: "unset"}}
                    control={
                    <WhiteCheckbox
                        checkedIcon={<CheckBoxOutlinedIcon/>}
                        checked={props.isTermAccepted}
                        onChange={({target:{checked}}) => props.onTermChanged(checked)}
                        name="terms"
                    />
                    }
                    label={<label id="terms-font">I understand the <u>Terms and Conditions*</u></label>}
                />
            </FormGroup>
            </Grid>
            <Grid container item xs={12} md={4} justify="center">
            <Button
                variant="contained" 
                className="button container bg-white"
                classes={{
                    label: "button-text"
                }}
                disabled={props.isStartSurveyDisabled} onClick={props.onStartSurveyClicked}>START SURVEY</Button>
            </Grid>
            <Grid item xs={12} md={4}>
                
            </Grid>
            
        </Grid>
    */ 
   )
}
